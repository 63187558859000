import { SideNavigation } from '@loadsmart/loadsmart-ui'
import Logo from 'atoms/Logo/Logo'
import { authManager } from 'common/authManager'
import Environment from 'common/types/Environment'
import { MouseEventHandler, ReactElement, ReactNode, useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import getEnv from '../../common/helpers/getEnv'
import styled from 'styled-components'
import {
  Check,
  DoorBack,
  Group,
  Logout,
  Mail,
  Map,
  Message,
  PausePresentation,
  Person,
  Power,
  Queue,
  Route,
  Search,
  Translate,
} from '@mui/icons-material'
import { Badge, Box, Divider, IconButton, Stack, Tooltip, useTheme } from '@mui/material'
import ShortenLogo from 'atoms/Logo/ShortenLogo'
import { ROUTES } from 'routing/routes'
import { useAppContext } from 'hooks/useUserContext/appUserContext'

export interface MenuItem {
  path?: string
  label: string
  tip?: string
  icon: ReactElement
  onClick?: Function
  subItems?: Array<MenuItem>
}

function MenuItemComponent({
  menuItem,
  children,
  onClick,
}: {
  menuItem: MenuItem
  children?: ReactNode
  onClick: MouseEventHandler
}) {
  let isActive = false
  const location = useLocation()
  isActive = location.pathname.startsWith(menuItem.path || 'unknown')
  return (
    <SideNavigation.Menu.Item
      key={`menu_item_${menuItem.path}`}
      label={menuItem.label}
      url={menuItem.path}
      active={isActive}
      onClick={(e: any) => {
        e.preventDefault()
        onClick(e)
      }}
      leading={menuItem.icon}
    >
      {children}
    </SideNavigation.Menu.Item>
  )
}

const StyledSidebar = styled(SideNavigation)`
  width: 100%;
`

const StyledSideBarIconButton = styled(IconButton)`
  :hover {
    background-color: ${props => props.theme.palette.primary.main};
  }
  & .MuiSvgIcon-root {
    color: ${props => props.theme.palette.primary.contrastText};
  }
`

export interface SidebarProps {
  toggled?: boolean
}

export const MENU_ITEMS: Array<MenuItem> = [
  {
    label: 'Go to',
    tip: 'Go to (ctrl + K)',
    icon: <Search />,
    onClick: () => {},
  },
  {
    label: 'Messages',
    icon: <Mail />,
    path: ROUTES.MESSAGES,
    subItems: [],
  },
  {
    label: 'Expected Messages ',
    icon: <Message />,
    path: ROUTES.EXPECTED_REPLY_MESSAGES,
    subItems: [],
  },
  {
    label: 'Trading Partner',
    icon: <Person />,
    path: ROUTES.TRADING_PARTNER,
    subItems: [],
  },
  {
    label: 'Routes',
    icon: <Route />,
    path: ROUTES.ROUTES,
    subItems: [],
  },
  {
    label: 'Gateways',
    icon: <DoorBack />,
    path: ROUTES.GATEWAYS,
    subItems: [],
  },
  {
    label: 'Connections',
    icon: <Power />,
    path: ROUTES.CONNECTIONS,
    subItems: [],
  },
  {
    label: 'Translation Maps',
    icon: <Map />,
    path: ROUTES.TRANSLATION_MAPS,
    subItems: [],
  },
  {
    label: 'Translation Settings',
    icon: <PausePresentation />,
    path: ROUTES.TRANSLATION_SETTINGS,
    subItems: [],
  },
  {
    label: 'Conversion Codes',
    icon: <Translate />,
    path: ROUTES.CONVERSION_CODES,
    subItems: [],
  },
  {
    label: 'Onboarding',
    icon: <Queue />,
    path: ROUTES.INTEGRATION_ONBOARDING,
    subItems: [],
  },
  {
    label: 'Partnership',
    icon: <Group />,
    path: ROUTES.PARTNERSHIP,
    subItems: [],
  },
  {
    label: 'Data Models',
    icon: <Check />,
    path: ROUTES.DATA_MODEL_SCHEMAS,
    subItems: [],
  },
  {
    label: 'Logout',
    icon: <Logout color="secondary" />,
    onClick: () => {
      authManager.logout()
    },
    subItems: [],
  },
]

export function Sidebar(properties: SidebarProps) {
  const [menuItems, setMenuItems] = useState([...MENU_ITEMS])
  const [menuItemsExceptLogout, setMenuItemsExceptLogout] = useState([...MENU_ITEMS])
  const env = getEnv()
  const envPrefixMapping: any = {
    [Environment.Dev]: 'DEV',
    [Environment.Sandbox]: 'SBX',
    [Environment.QA]: 'QA',
    [Environment.Staging]: 'STG',
  }
  const theme = useTheme()
  const history = useHistory()
  const appContext = useAppContext()

  const logoutMenu = menuItems.find(m => m.label === 'Logout')

  const openMenuItem = useCallback(
    (path: string | undefined) => {
      if (path) history.push(path || '')
    },
    [menuItems]
  )

  useEffect(() => {
    const goToMenuItem = menuItems.find(m => m.label === 'Go to')
    if (goToMenuItem) {
      goToMenuItem.onClick = () => {
        appContext.setGlobalSearchOpen(true)
      }
      setMenuItemsExceptLogout([
        goToMenuItem,
        ...menuItems
          .filter(m => m.label != 'Go to')
          .filter(m => RegExp('^(?!.*Logout).*$').test(m.label)),
      ])
    }
  }, [menuItems])

  useEffect(() => {
    if (env === Environment.Production) {
      setMenuItems(menuItems.filter(m => m.label !== 'Integration tests'))
    }
  }, [env])

  return (
    <Box>
      {!properties.toggled ? (
        <StyledSidebar>
          <SideNavigation.Logo url="/" className="cursor-pointer">
            <Logo width={150} />
          </SideNavigation.Logo>
          {menuItemsExceptLogout.map(m => {
            const hasSubItems = m.subItems && m.subItems.length > 0
            return (
              <MenuItemComponent
                key={`item_${m.path || m.label}`}
                menuItem={m}
                onClick={() => {
                  if (m.onClick) {
                    console.log('m.onClick', m.onClick)
                    m.onClick()
                  }
                  if (!hasSubItems) openMenuItem(m.path)
                }}
              >
                {m.subItems &&
                  m.subItems.length > 0 &&
                  m.subItems.map(item => (
                    <MenuItemComponent
                      key={`sub_item_${item.path}`}
                      menuItem={item}
                      onClick={() => {
                        if (item.onClick) {
                          item.onClick()
                        }
                        openMenuItem(item.path)
                      }}
                    />
                  ))}
              </MenuItemComponent>
            )
          })}

          <SideNavigation.Separator />
          {logoutMenu && logoutMenu.onClick != undefined ? (
            <SideNavigation.Menu>
              <MenuItemComponent
                key="menu_item_logout"
                onClick={() => (logoutMenu.onClick ? logoutMenu.onClick() : null)}
                menuItem={logoutMenu}
              />
            </SideNavigation.Menu>
          ) : null}
        </StyledSidebar>
      ) : (
        <Stack spacing={1} padding={1}>
          {env !== Environment.Production ? (
            <Badge
              badgeContent={envPrefixMapping[env]}
              color="warning"
              sx={{
                [`& .MuiBadge-standard`]: {
                  fontSize: '9px',
                },
              }}
            >
              <ShortenLogo width={40} height={30} />
            </Badge>
          ) : (
            <ShortenLogo width={40} height={30} />
          )}
          <Stack spacing={0.5} width={'fit-content'} paddingTop={6}>
            {menuItemsExceptLogout.map((m, index) => {
              return (
                <Tooltip key={index} title={m.tip || m.label} placement="right-start">
                  <StyledSideBarIconButton
                    theme={theme}
                    onClick={() => {
                      if (m.onClick) m.onClick()
                      openMenuItem(m.path)
                    }}
                  >
                    {m.icon && <>{m.icon}</>}
                  </StyledSideBarIconButton>
                </Tooltip>
              )
            })}
            <Divider color={theme.palette.getContrastText(theme.palette.common.black)} />

            {logoutMenu && logoutMenu.onClick != undefined && (
              <Tooltip title={logoutMenu.label}>
                <StyledSideBarIconButton
                  theme={theme}
                  onClick={() => {
                    if (logoutMenu.onClick) logoutMenu.onClick()
                  }}
                >
                  {logoutMenu.icon}
                </StyledSideBarIconButton>
              </Tooltip>
            )}
          </Stack>
        </Stack>
      )}
    </Box>
  )
}
