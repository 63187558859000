import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Breadcrumbs,
  Layout,
  Table,
  ToggleGroup,
  Text,
  TextField,
  Select,
  Dropdown,
  Tag,
  IconButton,
  Dialog,
} from '@loadsmart/loadsmart-ui'
import { LinearProgress } from '@mui/material'
import Loading from 'atoms/Loading'
import SimplePagination from 'atoms/SimplePagination'
import Icon from 'atoms/Icon'
import LSDate, { DATE_FORMAT_MMDDYYYYHHMM } from 'common/Date.helpers'
import { ConnectionProtocol, ConnectionSetting } from 'common/types/kraken-core/ConnectionSettings'
import { TradingPartner } from 'common/types/kraken-core/TradingPartner'
import { removeToken } from 'common/helpers/removeToken'
import ErrorPanel from 'molecules/ErrorPanel'
import EventLike from 'common/types/EventLike'
import useDebouncedValue from 'hooks/useDebouncedValue'
import { toast } from 'atoms/Toast'
import { parse, stringfy } from 'common/helpers/queryString'
import { isEmpty } from 'lodash'
import {
  useSearchConnectionSettings,
  onSearchTradingPartners,
  onSearchProtocol,
  useDeleteConnectionSetting,
} from '../../api'
import useTopNavigationContext from 'hooks/useTopNavigationContext/useTopNavigationContext'
import ImportModal from 'pages/Connections/components/ImportModal'
import { useTabTitle } from 'hooks/useTabTitle/useTabTitle'

const ACTIVE_FILTER_VALUES = [
  {
    label: 'Active',
    value: true,
  },
  {
    label: 'Inactive',
    value: false,
  },
]

const DATASOURCES = {
  TRADING_PARTNER: [
    () => ({
      type: 'string',
      adapter: {
        getKey: (tp: TradingPartner) => tp.id || '',
        getLabel: (tp: TradingPartner) => tp.name || '',
      },
      fetch: onSearchTradingPartners,
    }),
  ],
  PROTOCOLS: [
    () => ({
      type: 'string',
      adapter: {
        getKey: (tp: ConnectionProtocol) => tp.protocol || '',
        getLabel: (tp: ConnectionProtocol) => tp.description || '',
      },
      fetch: onSearchProtocol,
    }),
  ],
}

const ConnectionSettingsList = () => {
  useTabTitle('Connection Settings')
  const urlFilters = parse(window.location.search?.slice(1) ?? '')
  const [breadCrumbs] = useState([
    {
      label: 'Connection settings',
      active: true,
    },
  ])
  const [page, setPage] = useState(1)
  const [modalMode, setModalMode] = useState<'create' | 'import' | null>(null)
  const [connectionToDelete, setConnectionToDelete] = useState<ConnectionSetting | null>(null)
  const [filters, setFilters] = useState<any>(urlFilters)
  const debouncedFilters = useDebouncedValue<any>(filters, 500)
  const { isLoading, error, data, isFetching } = useSearchConnectionSettings({
    page,
    ...debouncedFilters,
  })
  const {
    mutate: deleteConnectionSetting,
    isLoading: isDeleting,
    isSuccess: isDeleted,
    error: deletedError,
    reset: deletedReset,
  } = useDeleteConnectionSetting()

  const topNavigationContext = useTopNavigationContext({})

  useEffect(() => {
    if (deletedError) {
      toast.error(`Something went wrong: ${String(deletedError)}`)
    }

    if (isDeleted) {
      toast.success('Deleted with success')
    }

    setTimeout(() => {
      deletedReset()
      setConnectionToDelete(null)
    }, 0)
  }, [isDeleted, deletedError])

  useEffect(() => {
    window.history.replaceState(null, '', `?${stringfy(filters)}`)
  }, [filters])

  useEffect(() => {
    topNavigationContext.updateState({
      children: (
        <Layout.Group align="center" justify="space-between" className="w-full">
          <Breadcrumbs entries={breadCrumbs} />
          <div>
            <Dropdown>
              <Dropdown.Trigger>Actions</Dropdown.Trigger>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setModalMode('create')}>Create</Dropdown.Item>
                <Dropdown.Item onClick={() => setModalMode('import')}>Import</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Layout.Group>
      ),
    })
  }, [breadCrumbs])

  const COLUMNS = [
    {
      Header: '#',
      Cell(row: ConnectionSetting) {
        const idAsString = row.id?.slice(0, 8) || '-'

        return (
          <Tag variant="default">
            <Link to={`connections/${row.id}`}>{idAsString}</Link>
          </Tag>
        )
      },
    },
    {
      Header: 'Name',
      Cell(row: ConnectionSetting) {
        return row.name
      },
    },
    {
      Header: 'Protocol',
      Cell(row: ConnectionSetting) {
        return row.protocol
      },
    },
    {
      Header: 'Created at',
      alignment: 'right',
      Cell(row: ConnectionSetting) {
        return LSDate(row.created_at)?.format(DATE_FORMAT_MMDDYYYYHHMM) || '-'
      },
    },
    {
      Header: 'Updated at',
      alignment: 'right',
      Cell(row: ConnectionSetting) {
        return LSDate(row.updated_at)?.format(DATE_FORMAT_MMDDYYYYHHMM) || '-'
      },
    },
    {
      Header: 'Active',
      alignment: 'right',
      Cell(row: ConnectionSetting) {
        return row.is_active ? (
          <Tag size="small" variant="accent">
            Active
          </Tag>
        ) : (
          <Tag size="small" variant="danger">
            Inactive
          </Tag>
        )
      },
    },
    {
      Header: 'Actions',
      alignment: 'right',
      Cell(row: ConnectionSetting) {
        return (
          <IconButton
            onClick={e => {
              setConnectionToDelete(row)
              e.stopPropagation()
            }}
          >
            <Icon name="remove" />
          </IconButton>
        )
      },
    },
  ]

  const handleFilterChange = (e: EventLike<any>) => {
    const { name, value } = e.target

    if (!name) return

    setFilters((prevState: any) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleCloseDeleteConfirmation = () => {
    setConnectionToDelete(null)
  }

  return (
    <>
      <ImportModal onClose={() => setModalMode(null)} open={!!modalMode} mode={modalMode} />

      <Dialog
        open={!!connectionToDelete}
        onOverlayClick={handleCloseDeleteConfirmation}
        scale="small"
      >
        <Dialog.Header>Delete</Dialog.Header>
        <Dialog.Body>Are you sure you want to delete this connection setting?</Dialog.Body>
        <Dialog.ActionConfirm
          disabled={isDeleting}
          onConfirm={() => connectionToDelete && deleteConnectionSetting(connectionToDelete.id)}
        />
        <Dialog.ActionCancel onCancel={handleCloseDeleteConfirmation} />
      </Dialog>

      {isLoading ? (
        <Loading className="mt-8" />
      ) : error ? (
        <ErrorPanel error={removeToken(JSON.stringify(error, null, 2))} />
      ) : (
        !!data && (
          <Layout.Stack className="w-full mt-6">
            <Layout.Stack>
              <Layout.Group align="flex-end">
                <div className="flex flex-col">
                  <Text>Name</Text>
                  <TextField
                    id="name"
                    name="name"
                    onChange={handleFilterChange}
                    value={filters.name}
                  />
                </div>
                <div className="flex flex-col">
                  <Text>Owner</Text>
                  <Select
                    id="owner"
                    name="owner"
                    onChange={e =>
                      handleFilterChange({
                        ...e,
                        target: {
                          ...e.target,
                          value: (e.target.value as TradingPartner)?.id,
                        },
                      })
                    }
                    datasources={DATASOURCES.TRADING_PARTNER}
                    value={
                      filters.owner
                        ? ({
                            label: filters.owner,
                          } as any)
                        : undefined
                    }
                  />
                </div>
                <div className="flex flex-col">
                  <Text>Protocol</Text>
                  <Select
                    id="protocol"
                    name="protocol"
                    onChange={e =>
                      handleFilterChange({
                        ...e,
                        target: {
                          ...e.target,
                          value: (e.target.value as ConnectionProtocol)?.protocol,
                        },
                      })
                    }
                    datasources={DATASOURCES.PROTOCOLS}
                    value={
                      filters.protocol
                        ? ({
                            label: filters.protocol,
                          } as any)
                        : undefined
                    }
                  />
                </div>
                <div>
                  <Text>Active</Text>
                  <ToggleGroup
                    id="is_active"
                    name="is_active"
                    className="flex justify-center"
                    onChange={handleFilterChange}
                    options={ACTIVE_FILTER_VALUES}
                    value={
                      !isEmpty(filters.is_active)
                        ? (ACTIVE_FILTER_VALUES.find(
                            v => String(v.value) === String(filters.is_active)
                          )?.value as any)
                        : undefined
                    }
                  />
                </div>
              </Layout.Group>
            </Layout.Stack>

            <LinearProgress className={isFetching ? 'opacity-100' : 'opacity-0'} />

            <Table className="w-full" data-testid="connection-settings-list">
              <Table.Head>
                {COLUMNS.map(column => (
                  <Table.Cell key={column.Header} alignment={column.alignment as any}>
                    {column.Header}
                  </Table.Cell>
                ))}
              </Table.Head>
              <Table.Body>
                {(data.results || []).map(row => (
                  <Table.Row key={row.id}>
                    {COLUMNS.map(cell => (
                      <Table.Cell
                        className="text-sm"
                        key={cell.Header}
                        alignment={cell.alignment as any}
                      >
                        {cell.Cell(row)}
                      </Table.Cell>
                    ))}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>

            <Layout.Box className="flex justify-end w-full">
              <SimplePagination
                currentPage={page}
                previousPage={() => setPage(prev => prev - 1)}
                nextPage={() => setPage(prev => prev + 1)}
                canPreviousPage={!!data.previous && !isFetching}
                canNextPage={!!data.next && !isFetching}
              />
            </Layout.Box>
          </Layout.Stack>
        )
      )}
    </>
  )
}

export default ConnectionSettingsList
