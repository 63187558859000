import { Chip, Grid, Typography } from '@mui/material'
import { TradingPartner } from 'common/types/kraken-core/TradingPartner'

export interface TradingPartnerItemResultProps {
  tradingPartner: TradingPartner
}

export const TradingPartnerItemResult = ({ tradingPartner }: TradingPartnerItemResultProps) => {
  return (
    <Grid container spacing={1} alignItems={'center'}>
      <Grid item>
        <Typography variant="body2">{tradingPartner.name}</Typography>
      </Grid>
      <Grid item>
        <Grid container spacing={1}>
          {tradingPartner.metadata &&
            tradingPartner.metadata.tags &&
            tradingPartner.metadata.tags.map(tag => {
              return (
                <Grid key={tag} item>
                  <Chip label={tag} size="small" />
                </Grid>
              )
            })}
        </Grid>
      </Grid>
    </Grid>
  )
}
