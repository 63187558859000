import { Button, Card, Layout, Select, Text, TextField, Breadcrumbs } from '@loadsmart/loadsmart-ui'
import { BreadcrumbProps } from '@loadsmart/loadsmart-ui/dist/components/Breadcrumbs'
import { onSearchTradingPartners } from 'common/helpers/kraken-core/hooks'
import { removeToken } from 'common/helpers/removeToken'
import EventLike from 'common/types/EventLike'
import { TradingPartner } from 'common/types/kraken-core/TradingPartner'
import { TranslationSettings } from 'common/types/kraken-core/TranslationSettings'
import useDebouncedValue from 'hooks/useDebouncedValue'
import useTopNavigationContext from 'hooks/useTopNavigationContext/useTopNavigationContext'
import isEmpty from 'lodash.isempty'
import ErrorPanel from 'molecules/ErrorPanel'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSearchTranslationSettings } from '../api'
import List from '../components/List'
import TranslationSettingsDrawer from './TranslationSettingsDrawer'
import { useTabTitle } from 'hooks/useTabTitle/useTabTitle'

const DATASOURCES = {
  TRADING_PARTNER: [
    () => ({
      type: 'string',
      adapter: {
        getKey: (tp: TradingPartner) => tp.id || '',
        getLabel: (tp: TradingPartner) => tp.name || '',
      },
      fetch: onSearchTradingPartners,
    }),
  ],
}

const INITIAL_FILTERS = {
  page_size: 10,
  name: '',
  translation_map_name: '',
}

const TranslationSettingsList = () => {
  useTabTitle('Translation Settings')
  const { id } = useParams<{ id: string }>()
  const [breadCrumbs] = useState<BreadcrumbProps[]>([
    {
      label: 'Translation Settings',
      active: true,
    },
  ])
  const [filters, setFilters] = useState<any>(INITIAL_FILTERS)
  const [detailDrawerIsOpen, setDetailDrawerIsOpen] = useState<boolean>(false)
  const [selectedTranslationSetting, setSelectedTranslationSetting] = useState<
    TranslationSettings
  >()
  const debouncedFilters = useDebouncedValue<any>(filters, 300)

  const { data: translationSettings, refetch, isLoading, error } = useSearchTranslationSettings({
    ...debouncedFilters,
  })

  const topNavigationContext = useTopNavigationContext()

  const handleFilterChange = (e: EventLike<any>) => {
    const { name, value } = e.target

    if (!name) return

    setFilters((prevState: any) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const clearFilters = (event: any) => {
    event.preventDefault()
    setFilters(INITIAL_FILTERS)
  }

  const handleOnDetailDrawerClose = () => {
    setDetailDrawerIsOpen(false)
    setSelectedTranslationSetting(undefined)
    setTimeout(() => {
      refetch()
    }, 300)
  }

  const handleOnDetailDrawerSave = () => {
    handleOnDetailDrawerClose()
  }

  const Filters = useMemo(() => {
    return (
      <Layout.Switcher>
        <Layout.Group align="center" style={{ flexBasis: 'auto' }}>
          <Layout.Stack space="s" style={{ width: '15vw' }}>
            <Text>Name</Text>
            <TextField
              key="name"
              name="name"
              data-testid="name_filter"
              onChange={handleFilterChange}
              value={String(filters.name)}
            />
          </Layout.Stack>
          <Layout.Stack space="s" style={{ width: '20vw' }}>
            <Text>Partner</Text>
            <Select
              key="trading_partner"
              name="trading_partner"
              data-testid="partner_filter"
              onChange={handleFilterChange}
              value={filters.trading_partner}
              datasources={DATASOURCES.TRADING_PARTNER}
            />
          </Layout.Stack>
          <Layout.Stack space="s" style={{ width: '20vw' }}>
            <Text>Map name</Text>
            <TextField
              key="transaltion_map_name"
              name="translation_map_name"
              data-testid="translation_map_name_filter"
              onChange={handleFilterChange}
              value={String(filters.translation_map_name)}
            />
          </Layout.Stack>
        </Layout.Group>
        <Layout.Group justify="flex-end" align="flex-end">
          <Button variant="tertiary" onClick={clearFilters}>
            Clear
          </Button>
        </Layout.Group>
      </Layout.Switcher>
    )
  }, [filters, handleFilterChange, clearFilters])

  useEffect(() => {
    if (!isEmpty(id)) {
      setSelectedTranslationSetting({ id })
      setDetailDrawerIsOpen(true)
    }
  }, [id])

  useEffect(() => {
    topNavigationContext.updateState({
      children: (
        <Layout.Group
          className="w-full"
          data-testid="header"
          align="center"
          justify="space-between"
        >
          <Layout.Group align="center" className="flex-1">
            <Layout.Box padding="none" className="flex-1">
              <Breadcrumbs entries={breadCrumbs} />
            </Layout.Box>
          </Layout.Group>
        </Layout.Group>
      ),
    })
  }, [breadCrumbs])

  if (error) return <ErrorPanel error={removeToken(JSON.stringify(error, null, 2))} />

  return (
    <Layout.Stack className="w-full">
      <TranslationSettingsDrawer
        open={detailDrawerIsOpen}
        onClose={handleOnDetailDrawerClose}
        onSave={handleOnDetailDrawerSave}
        translationSettingsId={selectedTranslationSetting?.id}
      />

      <List
        translationSettingsCollection={translationSettings?.results || []}
        filters={
          <Card data-testid="filters-card" className="w-full">
            <Card.Title>Filters</Card.Title>
            <Card.Separator />
            <Card.Body>{Filters}</Card.Body>
          </Card>
        }
        isLoading={isLoading}
        headerActions={
          <Button
            variant="primary"
            onClick={() => {
              setSelectedTranslationSetting(undefined)
              setDetailDrawerIsOpen(true)
            }}
          >
            Create new
          </Button>
        }
        onEdit={(ts: TranslationSettings) => {
          setSelectedTranslationSetting(ts)
          setDetailDrawerIsOpen(true)
        }}
        onDelete={(ts: TranslationSettings) => {
          if (refetch) refetch()
        }}
      />
    </Layout.Stack>
  )
}

export default TranslationSettingsList
