import { Chip, Tooltip } from '@mui/material'
import { TransactionType } from 'common/types/kraken-core/Message'

interface TransactionTypeTagProps {
  transaction_type: TransactionType
}

const commonPerfixesToBeRemoved = ['logistics']
const TransactionTypeTag = ({ transaction_type }: TransactionTypeTagProps) => {
  const formattedTransactionType = transaction_type.key

  return (
    <Tooltip title={transaction_type.description}>
      <Chip
        label={String(formattedTransactionType).toLocaleUpperCase()}
        size="small"
        sx={{
          fontWeight: 'bold',
        }}
      />
    </Tooltip>
  )
}

export default TransactionTypeTag
