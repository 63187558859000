import { INTEGRATION_ONBOARDING_ROUTES } from 'pages/IntegrationOnboarding/IntegrationOnboardingIndex'

export const ROUTES = {
  MESSAGES: '/messages',
  EXPECTED_REPLY_MESSAGES: '/expected-reply-messages',
  INTEGRATION_ONBOARDING: INTEGRATION_ONBOARDING_ROUTES.INDEX,
  TRADING_PARTNER: '/trading-partner',
  ROUTES: '/routes',
  GATEWAYS: '/gateways',
  CONNECTIONS: '/connections',
  TRANSLATION_MAPS: '/translation-maps',
  TRANSLATION_SETTINGS: '/translation-settings',
  PARTNERSHIP: '/partnership',
  INTEGRATION_TESTS: '/integration-tests',
  CONVERSION_CODES: '/conversion-codes',
  DATA_MODEL_SCHEMAS: '/data-model/schemas',
  OIDC_LOGIN_CALLBACK: '/oidc/login/callback',
  LOGIN: '/login',
  NOT_FOUND: '*',
}
