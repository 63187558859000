import { ControlProps } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { Banner, Layout, Select, Text } from '@loadsmart/loadsmart-ui'
import { SelectDatasourceFunction } from '@loadsmart/loadsmart-ui/dist/components/Select/Select.types'
import { ConnectionSetting } from 'common/types/kraken-core/ConnectionSettings'
import { GatewaySetting } from 'common/types/kraken-core/GatewaySettings'
import { TradingPartner } from 'common/types/kraken-core/TradingPartner'
import isEmpty from 'lodash.isempty'
import { onSearchConnections } from 'pages/Gateway/api'
import { useCallback, useEffect, useState } from 'react'
import { CustomRendererMetadata } from '../abstraction'
import {
  onSearchGateways,
  onSearchTradingPartners,
} from '../../../../common/components/datasources/datasource'

export const ENTITY_SELECTOR_RENDERER_META: CustomRendererMetadata = {
  type: 'EntitySelector',
  label: 'Entity Selector',
}

const DATASOURCES = {
  TRADING_PARTNER: [
    () => ({
      type: 'string',
      adapter: {
        getKey: (o: TradingPartner) => o.id || '',
        getLabel: (o: TradingPartner) => o.name || '',
      },
      fetch: onSearchTradingPartners,
    }),
  ],
  CONNECTIONS: [
    () => ({
      type: 'string',
      adapter: {
        getKey: (o: ConnectionSetting) => o.id || '',
        getLabel: (o: ConnectionSetting) => o.name.concat(`- ${o.protocol.toUpperCase()}`) || '',
      },
      fetch: onSearchConnections,
    }),
  ],
  GATEWAYS: [
    () => ({
      type: 'string',
      adapter: {
        getKey: (o: GatewaySetting) => o.id || '',
        getLabel: (o: GatewaySetting) => o.name || '',
      },
      fetch: onSearchGateways,
    }),
  ],
}

const ENTITY_DATASOURCE_MAPPING: any = {
  TradingPartner: DATASOURCES.TRADING_PARTNER,
  ConnectionSettings: DATASOURCES.CONNECTIONS,
  GatewaySettings: DATASOURCES.GATEWAYS,
}

export const EntitySelector = (props: ControlProps) => {
  const [datasource, setDatasource] = useState<SelectDatasourceFunction<any>[]>()
  const [entityType, setEntityType] = useState<string>(props.uischema.options?.entityType)
  const [fieldSet, setFieldSet] = useState<string[]>(props.uischema.options?.fieldSet)
  const [readOnly, setReadOnly] = useState<boolean>(props.uischema.options?.readOnly || false)
  const [isMultipleSelection, setMultipleSelection] = useState<boolean>(
    props.uischema.options?.multiple || false
  )
  const [errors, setErrors] = useState<string[]>([])

  const handleChange = useCallback(
    (value: any) => {
      let fieldSetValue: any = undefined
      if (value) {
        switch (props.schema.type) {
          case 'string': {
            fieldSetValue = ''
            const firstFieldSet = fieldSet.find(() => true) || 'id'
            if (isMultipleSelection) {
              // Multiple
              value.forEach((v: any) => {
                const extractedValue = v[firstFieldSet]
                if (isEmpty(fieldSetValue)) fieldSetValue = extractedValue
                fieldSetValue = fieldSetValue.concat(extractedValue)
              })
            } else {
              // Single
              const extractedValue = value[firstFieldSet].toString()
              fieldSetValue = extractedValue
            }
            break
          }
          case 'object': {
            fieldSet.forEach(field => {
              const extractedValue = value[field]
              if (!extractedValue) return
              fieldSetValue = {
                ...fieldSetValue,
                [field]: extractedValue,
              }
            })
            break
          }
          case 'array': {
            fieldSetValue = []
            setMultipleSelection(true)
            value.forEach((v: any) => {
              let extractedObject = {}
              for (const field of fieldSet) {
                const extractedValue = v[field]
                if (!extractedValue) continue
                extractedObject = {
                  ...extractedObject,
                  [field]: extractedValue,
                }
              }
              if (isEmpty(extractedObject)) return
              fieldSetValue.push(extractedObject)
            })
            break
          }
        }
      }
      props.handleChange(props.path, fieldSetValue)
    },
    [props, fieldSet, isMultipleSelection]
  )

  useEffect(() => {
    const options = props.uischema.options
    const fieldSet = options?.fieldSet || []
    const readOnly = options?.readOnly
    const entityType = options?.entityType || ''
    const multiple = options?.multiple || props.schema.type === 'array' || false
    const foundDatasource = ENTITY_DATASOURCE_MAPPING[entityType]
    setDatasource(foundDatasource)
    setEntityType(entityType)
    setFieldSet(fieldSet)
    setReadOnly(readOnly)
    setMultipleSelection(multiple)
  }, [props])

  useEffect(() => {
    setErrors([])
    const fieldType = props.schema.type?.toString() || ''
    if (['array', 'object', 'string'].findIndex(p => p === fieldType) < 0)
      setErrors(e => {
        return [...e, `Schema field type '${props.schema.type}' is not supported`]
      })

    if (!['array', 'object'].includes(fieldType) && fieldSet.length > 1) {
      setErrors(e => {
        return [...e, `When field type is not array or object, only one fieldSet is supported`]
      })
    }
  }, [props, fieldSet])

  return (
    <Layout.Stack className="p-2">
      <Text variant="heading-sm-bold">{props.label}</Text>
      {!isEmpty(errors) &&
        errors.map(e => {
          return <Banner key={e} variant="danger" title={e} />
        })}
      {isEmpty(errors) && datasource && (
        <Layout.Stack>
          <Select
            name={`entity-selector-${props.id}`}
            datasources={datasource}
            onChange={value => handleChange(value.target.value)}
            disabled={readOnly}
            value={isMultipleSelection ? null : props.data}
            multiple={isMultipleSelection}
            className="pb-4"
          />
        </Layout.Stack>
      )}
      {!datasource && (
        <Banner
          variant="danger"
          title={`There's no available datasource for entity type ${entityType}`}
        />
      )}
    </Layout.Stack>
  )
}

export default withJsonFormsControlProps(EntitySelector)
