/* eslint-disable no-unused-vars */
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query'
import SearchResponse from 'common/types/SearchResponse'
import {
  RuntimeTranslationMap,
  TranslationMap,
  TranslationMapContentType,
  TranslationMapEncoding,
  TranslationMapHistory,
  TranslationMapLoader,
  TranslationTestCase,
  TranslationTestCaseDetail,
  TranslationTestCaseExecutionResult,
} from 'common/types/kraken-core/TranslationMap'
import {
  searchTranslationMaps,
  getTranslationMap,
  searchTranslationMapsLoaders,
  searchTranslationMapsEncodings,
  searchTranslationMapsContentTypes,
  importTranslationMap,
  updateTranslationMapSetting,
  deleteTranslationMapSetting,
  updateImplementation,
  UpdateImplementationRequest,
  push,
  createTranslationTestCase,
  updateTranslationTestCase,
  deleteTranslationTestCase,
  searchTranslationTestCases,
  getTranslationTestCase,
  runTranslationTestCase,
  getRuntimeTranslationMap,
  getTranslationMapHistory,
} from 'services/kraken-core/translation_maps/translationMaps.service'
import downloadFile from 'common/helpers/downloadFile'

import {
  QUERIES,
  useCreateTranslationTestCaseParams,
  useDeleteTranslationTestCaseParams,
  useSearchTranslationTestCasesParams,
  useSearchTranslationMapsParams,
  useUpdateImplementationParams,
  useUpdateTranslationTestCaseParams,
  useGetTranslationTestCaseParams,
  useRunTranslationTestCaseParams,
  useCloneTranslationTestCaseParams,
  useGetTranslationMapParams,
} from './types'

export const useSearchTranslationMaps = (
  params: useSearchTranslationMapsParams
): UseQueryResult<SearchResponse<TranslationMap[]>> => {
  const queryFn = async ({ page, loader, name, direction }: useSearchTranslationMapsParams) => {
    const filters = {
      name,
      direction,
      loader: loader?.loader,
    }
    const { response, error } = await searchTranslationMaps({
      ...filters,
      page: page || 1,
      page_size: 10,
    })
    if (error) throw error
    return response?.data || {}
  }

  const query = useQuery([QUERIES.SEARCH_TRANSLATION_MAP, params], () => queryFn(params), {
    keepPreviousData: true,
  })

  return query
}

export const useGetTranslationMap = (id?: string): UseQueryResult<TranslationMap> => {
  const queryFn = async (idToGet?: string) => {
    if (!idToGet) return {}
    const { response, error } = await getTranslationMap(idToGet)
    if (error) throw error
    return response?.data || {}
  }
  const query = useQuery([QUERIES.GET_TRANSLATION_MAP, id], () => queryFn(id), { enabled: !!id })
  return query
}

export const useGetRuntimeTranslationMap = (id?: string): UseQueryResult<RuntimeTranslationMap> => {
  const queryFn = async (idToGet?: string) => {
    if (!idToGet) return {}
    const { response, error } = await getRuntimeTranslationMap(idToGet)
    if (error) throw error
    return response?.data || {}
  }
  const query = useQuery([QUERIES.GET_TRANSLATION_MAP, id], () => queryFn(id), { enabled: !!id })
  return query
}

export const useSearchLoaders = (): UseQueryResult<TranslationMapLoader[]> => {
  const queryFn = async () => {
    const { response, error } = await searchTranslationMapsLoaders()
    if (error) throw error
    return response?.data || []
  }
  const query = useQuery([QUERIES.SEARCH_LOADERS], () => queryFn())
  return query
}

export const useSearchEncodings = (): UseQueryResult<TranslationMapEncoding[]> => {
  const queryFn = async () => {
    const { response, error } = await searchTranslationMapsEncodings()
    if (error) throw error
    return response?.data || []
  }
  const query = useQuery([QUERIES.SEARCH_ENCODINGS], () => queryFn())
  return query
}

export const useSearchContentTypes = (): UseQueryResult<TranslationMapContentType[]> => {
  const queryFn = async () => {
    const { response, error } = await searchTranslationMapsContentTypes()
    if (error) throw error
    return response?.data || []
  }
  const query = useQuery([QUERIES.SEARCH_CONTENT_TYPES], () => queryFn())
  return query
}

export const onSearchLoaders = async (): Promise<TranslationMapLoader[]> => {
  const { error, response } = await searchTranslationMapsLoaders()
  if (error) throw error
  return response?.data || []
}

export const onExport = async (idToExport: string, name: string) => {
  const result = await getTranslationMap(idToExport)
  if (result.response?.data) {
    const { created_at, updated_at, deleted_at, id, ...rest } = result.response
      .data as TranslationMap
    downloadFile(JSON.stringify(rest), `translation-map_${name}.json`, 'application/json')
  }
}

export const useImportTranslationMap = (): UseMutationResult<
  any,
  unknown,
  TranslationMap,
  unknown
> => {
  const queryClient = useQueryClient()
  const mutation = useMutation(
    async (params: TranslationMap) =>
      importTranslationMap({
        ...params,
      }),
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries([QUERIES.SEARCH_TRANSLATION_MAP])
      },
    }
  )
  return mutation
}

export const useUpdateTranslationMapSetting = (): UseMutationResult<
  any,
  unknown,
  TranslationMap,
  unknown
> => {
  const queryClient = useQueryClient()
  const mutation = useMutation(
    async (params: TranslationMap) => {
      console.log(params)
      const { response } = await updateTranslationMapSetting({
        ...params,
      })

      return response?.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERIES.SEARCH_TRANSLATION_MAP])
      },
    }
  )

  return mutation
}

export const useDeleteTranslationMapSetting = (): UseMutationResult<
  any,
  unknown,
  string,
  unknown
> => {
  const queryClient = useQueryClient()
  const mutation = useMutation(
    async (id: string) => {
      const { response } = await deleteTranslationMapSetting(id)

      return response?.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERIES.SEARCH_TRANSLATION_MAP])
      },
    }
  )
  return mutation
}

export const useUpdateImplementation = (): UseMutationResult<
  any,
  unknown,
  useUpdateImplementationParams,
  unknown
> => {
  const mutation = useMutation(async (params: useUpdateImplementationParams) => {
    const input: UpdateImplementationRequest = {
      id: params.id,
      encoded_implementation: params.encoded_implementation,
    }
    const { response } = await updateImplementation(input)
    return response?.data
  })
  return mutation
}

export const usePush = (): UseMutationResult<any, unknown, TranslationMap, unknown> => {
  const mutation = useMutation(async (params: TranslationMap) => {
    const { response, error } = await push(params)
    if (error) throw JSON.stringify(error.response?.data)
    return response?.data
  })
  return mutation
}

export const useGetTranslationTestCase = (
  params: useGetTranslationTestCaseParams,
  options?: any
): UseQueryResult<TranslationTestCaseDetail> => {
  const queryFn = async (params: useGetTranslationTestCaseParams) => {
    const { response, error } = await getTranslationTestCase(
      params.translationMapId,
      params.translationTestCaseId
    )
    if (error) throw error
    return response?.data || {}
  }
  const query = useQuery([QUERIES.GET_TRANSLATION_TEST_CASE, params], () => queryFn(params), {
    ...options,
  })
  return query
}

export const useSearchTranslationTestCases = (
  params: useSearchTranslationTestCasesParams,
  options?: any
): UseQueryResult<SearchResponse<TranslationTestCase[]>> => {
  const queryFn = async (params: useSearchTranslationTestCasesParams) => {
    const { response, error } = await searchTranslationTestCases(params.translationMapId)
    if (error) throw error
    return response?.data || {}
  }
  const query = useQuery([QUERIES.SEARCH_TRANSLATION_TEST_CASES, params], () => queryFn(params), {
    ...options,
  })
  return query
}

export const useCreateTranslationTestCase = (): UseMutationResult<
  any,
  unknown,
  useCreateTranslationTestCaseParams,
  unknown
> => {
  const mutation = useMutation(async (params: useCreateTranslationTestCaseParams) => {
    const { response, error } = await createTranslationTestCase(
      params.translationMapId,
      params.translationTestCase
    )
    if (error) throw error
    return response?.data
  })
  return mutation
}

export const useUpdateTranslationTestCase = (): UseMutationResult<
  any,
  unknown,
  useUpdateTranslationTestCaseParams,
  unknown
> => {
  const mutation = useMutation(async (params: useUpdateTranslationTestCaseParams) => {
    const { response, error } = await updateTranslationTestCase(
      params.translationMapId,
      params.translationTestCase
    )
    if (error) throw error
    return response?.data
  })
  return mutation
}

export const useDeleteTranslationTestCase = (): UseMutationResult<
  any,
  unknown,
  useDeleteTranslationTestCaseParams,
  unknown
> => {
  const mutation = useMutation(async (params: useDeleteTranslationTestCaseParams) => {
    const { response, error } = await deleteTranslationTestCase(
      params.translationMapId,
      params.translationTestCaseId
    )
    if (error) throw error
    return response?.data
  })
  return mutation
}

export const useRunTranslationTestCase = (): UseMutationResult<
  TranslationTestCaseExecutionResult,
  unknown,
  useRunTranslationTestCaseParams,
  unknown
> => {
  const mutation = useMutation(async (params: useRunTranslationTestCaseParams) => {
    const { response, error } = await runTranslationTestCase(
      params.translationMapId,
      params.translationTestCaseId
    )
    if (error) throw error
    return response?.data
  })
  return mutation
}

export const useCloneTranslationTestCase = (): UseMutationResult<
  any,
  unknown,
  useCloneTranslationTestCaseParams,
  unknown
> => {
  const mutation = useMutation(async (params: useCloneTranslationTestCaseParams) => {
    const { response: detailResponse, error: detailError } = await getTranslationTestCase(
      params.translationMapId,
      params.translationTestCaseId
    )

    if (detailError) throw detailError

    const cloned = {
      ...detailResponse?.data,
      id: undefined,
    }

    const {
      response: createdCloneResponse,
      error: createdCloneError,
    } = await createTranslationTestCase(params.translationMapId, cloned)

    if (createdCloneError) throw createdCloneError
    return createdCloneResponse?.data
  })
  return mutation
}

export const useGetTranslationMapHistory = (
  params: useGetTranslationMapParams
): UseQueryResult<TranslationMapHistory[]> => {
  const queryFn = async ({ id }: useGetTranslationMapParams) => {
    if (!id) return {}

    const { response } = await getTranslationMapHistory(id)

    return response?.data || {}
  }

  const query = useQuery([QUERIES.GET_TRANSLATION_MAP_HISTORY, params], () => queryFn(params), {
    enabled: !!params.id,
  })

  return query
}
