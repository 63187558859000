import { useParams } from 'react-router'
import TemplateOnboardDashboardContextWrapper from './TemplateOnboardDashboardContext'
import { useTabTitle } from 'hooks/useTabTitle/useTabTitle'
import useTopNavigationContext from 'hooks/useTopNavigationContext/useTopNavigationContext'
import { Breadcrumbs, Layout } from '@loadsmart/loadsmart-ui'
import TemplateOnboardDashboard from './TemplateOnboardingDashboard'
import { Suspense } from 'react'
import { useRouteMatch, Switch, Route } from 'react-router-dom'
export interface TemplateOnboardIndexProps {
  templateId: string
}

function TemplateOnboardIndex() {
  useTabTitle('Integration Onboarding')
  const { templateId } = useParams<TemplateOnboardIndexProps>()
  const { path } = useRouteMatch()

  useTopNavigationContext({
    children: (
      <Layout.Group className="w-full" align="center" justify="space-between">
        <Breadcrumbs
          entries={[
            {
              label: 'Template Onboard Dashboard',
              active: true,
            },
          ]}
        />
      </Layout.Group>
    ),
  })

  return (
    <TemplateOnboardDashboardContextWrapper templateId={templateId}>
      <Suspense fallback={<h1> Loading ...</h1>}>
        <Switch>
          <Route path={`${path}`}>
            <TemplateOnboardDashboardContextWrapper templateId={templateId}>
              <TemplateOnboardDashboard />
            </TemplateOnboardDashboardContextWrapper>
          </Route>
        </Switch>
      </Suspense>
    </TemplateOnboardDashboardContextWrapper>
  )
}

export default TemplateOnboardIndex
