import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { SnackbarProvider } from 'notistack'
import { Router } from '../routing'
import ToastHost from '../atoms/Toast'
import CustomThemeProvider from './CustomThemeProvider'
import LoginModal from './LoginModal'

import './App.css'
import { AppContextProvider } from 'hooks/useUserContext/appUserContext'
import { SecurityContextProvider } from 'hooks/useSecurity/useSecurity'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      useErrorBoundary: true,
    },
  },
})

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider maxSnack={3}>
        <BrowserRouter>
          <CustomThemeProvider>
            <AppContextProvider>
              <SecurityContextProvider>
                <LoginModal />
                <ToastHost />
                <Router />
              </SecurityContextProvider>
            </AppContextProvider>
          </CustomThemeProvider>
        </BrowserRouter>
      </SnackbarProvider>
    </QueryClientProvider>
  )
}

export default App
