import {
  Banner,
  BannerLarge,
  Layout,
  Switch,
  Tabs,
  Tag,
  Text,
  Tooltip,
} from '@loadsmart/loadsmart-ui'
import {
  ConnectionSetting,
  ConnectionSettingsSchema,
} from 'common/types/kraken-core/ConnectionSettings'
import FileViewer, { FileViewTheme } from 'molecules/FileViewer/FileViewer'
import JsonForm from 'molecules/JsonForm/JsonForm'
import { useCallback, useEffect, useRef, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import localProtocolSchemas from '../schema/protocols'
import ProcessorCollection from '../../../common/components/processor/ProcessorCollection'
import { useGetProtocolSchema } from '../api'
import { parseErrorsAsString } from 'molecules/JsonForm/common'

export interface DefinitionValidationResult {
  isValid: boolean
  errors?: string
}

export interface ProtocolDefinitionProps {
  connection: ConnectionSetting
  onChangeRawDefinition: (value: any) => void
  onChangeProtocolDefinition: (value: any) => void
  onValidationResult: (result: DefinitionValidationResult) => void
}

const FallbackErrorRoot = ({ error, resetErrorBoundary }: any) => (
  <div>Error when rendering definition: {error}</div>
)

const FallbackErrorProcessorForm = ({ error, resetErrorBoundary }: any) => (
  <div>Error when rendering Processors form: {error}</div>
)

const FallbackErrorProtocolForm = ({ error, resetErrorBoundary }: any) => (
  <div>Error when rendering Protocol form: {error}</div>
)

const PRE_PROCESSOR_KEY = 'connector:processors:pre'
const POST_PROCESSOR_KEY = 'connector:processors:post'

function ConnectionSettingsDefitinion({
  connection,
  onChangeRawDefinition,
  onChangeProtocolDefinition,
  onValidationResult,
}: ProtocolDefinitionProps) {
  const [rawDefinition, setRawDefinition] = useState<any>(connection.definition)
  const [isRawDefinitionView, setRawDefintionView] = useState<boolean>(false)
  const [schema, setSchema] = useState({})
  const [uiSchema, setUiSchema] = useState()
  const [protocolDefinitionData, setProtocolDeftinionData] = useState<any>({})
  const [protocolDefintionValidation, setProtocolDeftinionValidation] = useState<
    DefinitionValidationResult
  >({
    isValid: true,
  })
  const remoteProtocolSchema = useRef<ConnectionSettingsSchema | undefined>(undefined)
  const [preProcessors, setPreProcessors] = useState<any>()
  const [postProcessors, setPostProcessors] = useState<any>()

  const { isLoading: isLoadingProtocolSchema } = useGetProtocolSchema(
    {
      protocol: connection.protocol,
    },
    {
      enabled: !!connection.protocol,
      onSuccess: (data: ConnectionSettingsSchema) => {
        remoteProtocolSchema.current = data
      },
    }
  )

  const processValidationResult = useCallback(
    async (output: any) => {
      let validationResult: DefinitionValidationResult = {
        isValid: true,
      }
      if (output && output.errors && output.errors.length > 0) {
        const errors = parseErrorsAsString(output.errors)
        validationResult = {
          isValid: false,
          errors,
        }
      }
      setProtocolDeftinionValidation(validationResult)
      onValidationResult(validationResult)
    },
    [protocolDefinitionData]
  )

  const updateRawDefinitionByKey = useCallback(
    (key: string, value: any) => {
      const definition = {
        ...rawDefinition,
        [key]: value,
      }
      setRawDefinition(definition)
    },
    [rawDefinition]
  )

  const getProcessorsFromRawDefinition = (key: string) => {
    const content = rawDefinition[key]
    return content && Array.isArray(content) ? content : []
  }

  const initializeDefinition = async () => {
    setPreProcessors(
      Array.isArray(rawDefinition[PRE_PROCESSOR_KEY]) ? rawDefinition[PRE_PROCESSOR_KEY] : []
    )
    setPostProcessors(
      Array.isArray(rawDefinition[POST_PROCESSOR_KEY]) ? rawDefinition[POST_PROCESSOR_KEY] : []
    )

    if (connection?.protocol) {
      setProtocolDeftinionData(rawDefinition[connection.protocol] || {})

      let { [connection.protocol]: localProtocolSchema } = localProtocolSchemas

      // Override remote schema in favor of local if any
      if (remoteProtocolSchema.current) {
        let localProtocolSchemaModel
        if (localProtocolSchema && localProtocolSchema.model)
          localProtocolSchemaModel = localProtocolSchema.model
        const remoteProtocolSchemaModel = JSON.parse(remoteProtocolSchema.current.schema || '{}')
        localProtocolSchema = {
          ...localProtocolSchema,
          model: localProtocolSchemaModel ?? remoteProtocolSchemaModel,
        }
      }

      if (!localProtocolSchema) {
        setSchema({})
        setUiSchema(undefined)
        setRawDefintionView(true)
        return
      }

      if (localProtocolSchema.model) {
        setSchema(localProtocolSchema.model)
        setUiSchema(localProtocolSchema.ui)
        setRawDefintionView(false)
      }
    }
  }

  useEffect(() => {
    remoteProtocolSchema.current = undefined
    initializeDefinition()
  }, [connection.protocol])

  useEffect(() => {
    if (!remoteProtocolSchema.current) return
    initializeDefinition()
  }, [remoteProtocolSchema.current])

  useEffect(
    function onSwitchRawView() {
      if (isRawDefinitionView) return
      initializeDefinition()
    },
    [isRawDefinitionView]
  )

  useEffect(() => {
    // Force async update
    setTimeout(() => {
      setRawDefinition(connection.definition)
      initializeDefinition()
    }, 0)
  }, [connection.definition])

  useEffect(() => {
    onChangeRawDefinition(JSON.stringify(rawDefinition))
  }, [rawDefinition])

  return (
    <ErrorBoundary FallbackComponent={FallbackErrorRoot}>
      <Layout.Stack>
        <Layout.Group className="mb-4" align="center" space="m">
          <Switch onToggle={() => setRawDefintionView(v => !v)} active={isRawDefinitionView} />
          <Text>Raw visualization</Text>
        </Layout.Group>

        {!protocolDefintionValidation.isValid && (
          <Banner
            scale="default"
            title={String(protocolDefintionValidation.errors)}
            variant="danger"
            dismissible={false}
          />
        )}

        {isRawDefinitionView && !isLoadingProtocolSchema && (
          <Layout.Stack>
            <FileViewer
              options={{
                heigth: 500,
                theme: FileViewTheme.DEFAULT,
              }}
              content={JSON.stringify(rawDefinition, null, 2)}
              contentType="application/json"
              onChange={(value: any) => {
                const definition = JSON.parse(value)
                setRawDefinition(definition)
                setProtocolDeftinionData(definition[connection.protocol])
                onChangeRawDefinition(value)
              }}
            />
          </Layout.Stack>
        )}

        {!isRawDefinitionView && connection.protocol && !isLoadingProtocolSchema && (
          <Layout.Stack>
            <ErrorBoundary FallbackComponent={FallbackErrorProcessorForm}>
              <Tabs>
                <Tabs.Items>
                  <Tabs.Item
                    default
                    name="protocol-definition"
                    data-testid="protocol-definition-tab"
                  >
                    <Layout.Group align="center">
                      <div data-testid="protocol-definition-tab-name" className="align-middle p-1">
                        {`${connection.protocol.toUpperCase()} Definition`}
                      </div>
                      {!protocolDefintionValidation?.isValid && (
                        <Tooltip message={protocolDefintionValidation.errors}>
                          <Tag variant="danger">!!</Tag>
                        </Tooltip>
                      )}
                    </Layout.Group>
                  </Tabs.Item>
                  <Tabs.Item name="pre-processors" data-testid="pre-processors-tab">
                    <Layout.Group align="center">
                      Pre-Processors
                      {preProcessors && (
                        <Tag variant="accent">
                          {getProcessorsFromRawDefinition(PRE_PROCESSOR_KEY).length}
                        </Tag>
                      )}
                    </Layout.Group>
                  </Tabs.Item>
                  <Tabs.Item name="post-processors" data-testid="post-processors-tab">
                    <Layout.Group align="center">
                      Post-Processors
                      {postProcessors && (
                        <Tag variant="accent">
                          {getProcessorsFromRawDefinition(POST_PROCESSOR_KEY).length}
                        </Tag>
                      )}
                    </Layout.Group>
                  </Tabs.Item>
                </Tabs.Items>
                <Tabs.Panels>
                  <Tabs.Panel
                    name="protocol-definition"
                    data-testid="protocol-definition-tab-panel"
                  >
                    <ErrorBoundary FallbackComponent={FallbackErrorProtocolForm}>
                      <Layout.Stack>
                        {remoteProtocolSchema.current && remoteProtocolSchema.current.doc && (
                          <div data-testid="">
                            <BannerLarge
                              title={`${connection.protocol.toUpperCase()} Documentation`}
                              description={remoteProtocolSchema.current?.doc}
                            />
                          </div>
                        )}

                        <div data-testid="protocol-definition-dynamic-form">
                          <JsonForm
                            key="json-form"
                            data-testid="protocol-definition-component"
                            schema={schema}
                            data={protocolDefinitionData}
                            uiSchema={uiSchema}
                            onChange={value => {
                              if (!value) return
                              setProtocolDeftinionData(value.data)
                              updateRawDefinitionByKey(connection.protocol, value.data)
                              onChangeProtocolDefinition(value.data)
                              processValidationResult(value)
                            }}
                          />
                        </div>
                      </Layout.Stack>
                    </ErrorBoundary>
                  </Tabs.Panel>
                  <Tabs.Panel name="pre-processors" data-testid="pre-processors-tab-panel">
                    <ProcessorCollection
                      key="pre-processors"
                      data={preProcessors}
                      onChange={value => {
                        updateRawDefinitionByKey(PRE_PROCESSOR_KEY, value)
                      }}
                      title="Pre-Processors"
                    />
                  </Tabs.Panel>
                  <Tabs.Panel name="post-processors" data-testid="post-processors-tab-panel">
                    <ProcessorCollection
                      key="post-processors"
                      data={postProcessors}
                      onChange={value => {
                        updateRawDefinitionByKey(POST_PROCESSOR_KEY, value)
                      }}
                      title="Post-Processors"
                    />
                  </Tabs.Panel>
                </Tabs.Panels>
              </Tabs>
            </ErrorBoundary>
          </Layout.Stack>
        )}
      </Layout.Stack>
    </ErrorBoundary>
  )
}

export default ConnectionSettingsDefitinion
