// General imports, like internal and external libraries
import Loading from 'atoms/Loading'
import { Suspense } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router'
import PartnershipList from './containers/PartnershipList'

const PartnershipIndex = () => {
  const { path } = useRouteMatch()

  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route exact path={path}>
          <PartnershipList />
        </Route>
        <Route path={`${path}/:id`} exact>
          <PartnershipList />
        </Route>
      </Switch>
    </Suspense>
  )
}

export default PartnershipIndex
