import { useState, useEffect, createContext, useRef, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  Breadcrumbs,
  Dropdown,
  Layout,
  Button,
  Tabs,
  Card,
  BannerLarge,
} from '@loadsmart/loadsmart-ui'
import Loading from 'atoms/Loading'
import { toast } from 'atoms/Toast'
import { removeToken } from 'common/helpers/removeToken'
import ErrorPanel from 'molecules/ErrorPanel'
import { BreadcrumbProps } from '@loadsmart/loadsmart-ui/dist/components/Breadcrumbs'
import { TranslationMap } from 'common/types/kraken-core/TranslationMap'
import { useGetTranslationMap, onExport, useUpdateTranslationMapSetting, usePush } from '../api'
import GeneralDetails from 'pages/TranslationMaps/components/GeneralDetails'
import SettingsDetails from 'pages/TranslationMaps/components/SettingsDetails'
import DevelopmentDetails from '../components/development/DevelopmentDetails'
import useTopNavigationContext from 'hooks/useTopNavigationContext/useTopNavigationContext'
import PushableEntityStatusTag from '../../../common/components/development/PusableEntityStatus'
import { validateFields } from 'common/helpers/kraken-core/translationMaps.helper'
import isEmpty from 'lodash.isempty'
import { useTabTitle } from 'hooks/useTabTitle/useTabTitle'
import IaCComponentReferenceWarning from 'atoms/IaCComponentReferenceWarning/IaCComponentReferenceWarning'
import HistoryComponent from '../components/HistoryComponent'

export interface TranslationMapDetailsContextProps {
  translationMap?: TranslationMap | undefined
  save?: () => void
  refetch?: () => void
}

export const TranslationMapDetailsContext = createContext<TranslationMapDetailsContextProps>({})

const TranslationMapDetails = () => {
  useTabTitle('Translation Map')
  const browserHistory = useHistory()
  const { id } = useParams<{ id: string }>()
  const [breadCrumbs, setBreadcrumbs] = useState<BreadcrumbProps[]>([
    {
      label: 'Translation maps',
      active: true,
      url: '#',
      onClick: () => {
        browserHistory.push('/translation-maps')
      },
    },
  ])

  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [translationMapData, setTranslationMapData] = useState<TranslationMap>()
  const [validations, setValidations] = useState<string>()

  const { isLoading, error, data: rawData, refetch } = useGetTranslationMap(id)

  const handleExport = useRef<Function>(() => {})
  const handleSave = useRef<Function>(() => {})
  const handlePush = useRef<Function>(() => {})

  const {
    mutate: updateTranslationMapSetting,
    isLoading: isUpdating,
    isSuccess: isUpdated,
    error: updatedError,
    reset: updateReset,
  } = useUpdateTranslationMapSetting()

  const {
    mutate: push,
    isLoading: isPushing,
    isSuccess: isPushSuccess,
    isError: isPushError,
    error: pushError,
  } = usePush()

  const handleRefetch = () => {
    setTimeout(() => {
      refetch()
    }, 1000)
  }

  handleExport.current = () => {
    if (!translationMapData?.id) return
    onExport(translationMapData.id, translationMapData.name)
  }

  handlePush.current = () => {
    if (translationMapData) push(translationMapData)
    else {
      toast.error(`There's no translation map selected`)
    }
  }

  handleSave.current = () => {
    try {
      setValidations(undefined)
      validateFields(translationMapData as any)
      updateTranslationMapSetting(translationMapData as TranslationMap)
      handleRefetch()
    } catch (error) {
      setValidations(`Something went wrong: ${error}`)
    }
  }

  const onFormUpdate = (translationMap: TranslationMap) => {
    setIsDirty(true)
    setTranslationMapData(translationMap)
  }

  const onSettingsUpdate = (translationMap: TranslationMap) => {
    setIsDirty(true)
    setTranslationMapData(translationMap)
  }
  const topNavigationContext = useTopNavigationContext()

  const history = useMemo(() => <HistoryComponent id={id} />, [id])

  useEffect(() => {
    if (rawData) {
      setBreadcrumbs(prev => [
        prev[0],
        {
          label: rawData.name,
        },
      ])
      setTranslationMapData(rawData)
    }
  }, [rawData])

  useEffect(() => {
    if (updatedError) {
      toast.error(`Something went wrong: ${String(updatedError)}`)
    }

    if (isUpdated) {
      toast.success('Updated with success')
    }
    handleRefetch()
  }, [isUpdated, updatedError])

  useEffect(() => {
    if (isPushError) {
      toast.error(`Error when pushing: ${pushError}`)
      return
    }

    if (isPushSuccess) {
      toast.success(`Successfully pushed`)
    }
    handleRefetch()
  }, [isPushError, isPushing])

  useEffect(() => {
    topNavigationContext.updateState({
      children: (
        <Layout.Group
          className="w-full"
          data-testid="header"
          align="center"
          justify="space-between"
        >
          <Layout.Group align="center" className="flex-1">
            <Layout.Box padding="none" className="flex-1">
              <Breadcrumbs entries={breadCrumbs} />
            </Layout.Box>

            {translationMapData?.status && (
              <PushableEntityStatusTag status={translationMapData?.status} />
            )}
            <Button
              variant="primary"
              disabled={!isDirty || isUpdating}
              onClick={() => handleSave.current()}
            >
              Save
            </Button>
            <Layout.Box padding="none">
              <Dropdown>
                <Dropdown.Trigger>Actions</Dropdown.Trigger>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => handleExport.current()}>Export</Dropdown.Item>
                  <Dropdown.Item onClick={() => handlePush.current()}>Push</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Layout.Box>
          </Layout.Group>
        </Layout.Group>
      ),
    })
  }, [breadCrumbs, isDirty, isUpdating, translationMapData])

  return (
    <TranslationMapDetailsContext.Provider
      value={{
        save: () => handleSave.current(),
        refetch: refetch,
        translationMap: translationMapData,
      }}
    >
      <Layout.Stack className="w-full" space="none">
        {isLoading && <Loading className="mt-8 justify-center" />}

        {rawData && rawData.iac_reference ? <IaCComponentReferenceWarning /> : null}

        {error && <ErrorPanel error={removeToken(JSON.stringify(error, null, 2))} />}
        {!isEmpty(validations) ? <BannerLarge description={validations} variant="danger" /> : null}

        {rawData && (
          <Tabs>
            <Tabs.Items>
              <Tabs.Item name="general" default>
                General
              </Tabs.Item>
              <Tabs.Item name="settings">
                <Layout.Group>Settings</Layout.Group>
              </Tabs.Item>
              <Tabs.Item name="development">
                <Layout.Group>Development</Layout.Group>
              </Tabs.Item>
              <Tabs.Item name="history">
                <Layout.Group>History</Layout.Group>
              </Tabs.Item>
            </Tabs.Items>

            <Tabs.Panels>
              <Tabs.Panel name="general">
                <Card data-testid="general-details" className="p-4 w-full">
                  <GeneralDetails translationMap={rawData} onUpdate={onFormUpdate} />
                </Card>
              </Tabs.Panel>
              <Tabs.Panel name="settings">
                <Card data-testid="settings-details" className="p-4 w-full">
                  <SettingsDetails translationMap={rawData} onUpdate={onSettingsUpdate} />
                </Card>
              </Tabs.Panel>
              <Tabs.Panel name="development">
                <Card data-testid="development-details" className="p-4 w-full">
                  <DevelopmentDetails translationMap={rawData} />
                </Card>
              </Tabs.Panel>
              <Tabs.Panel name="history">
                <Card data-testid="history-details" className="p-4 w-full">
                  {history}
                </Card>
              </Tabs.Panel>
            </Tabs.Panels>
          </Tabs>
        )}
      </Layout.Stack>
    </TranslationMapDetailsContext.Provider>
  )
}
export default TranslationMapDetails
