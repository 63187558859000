import { useTabTitle } from 'hooks/useTabTitle/useTabTitle'
import IntegrationTestCasePlayground from './IntegrationTestCase/containers/IntegrationTestCasePlayground'
import IntegrationTestContextProvider from './IntegrationTestContext'

export default function IntegrationTestDashboard() {
  useTabTitle('Integration Tests')
  return (
    <IntegrationTestContextProvider>
      <IntegrationTestCasePlayground />
    </IntegrationTestContextProvider>
  )
}
