import { TopNavigation } from '@loadsmart/loadsmart-ui'
import { authManager } from 'common/authManager'
import { useContext } from 'react'
import TopNavigationContext from './TopNavigationContext'
import { Box } from '@mui/material'

function TopNavigationHeader() {
  const context = useContext(TopNavigationContext)

  if (!authManager.isAuthenticated()) {
    return null
  }

  return (
    <Box
      sx={{
        zIndex: 1,
        position: 'sticky',
        top: 0,
        width: '100%',
      }}
    >
      <TopNavigation data-testid="top-navigation">{context.currentState?.children}</TopNavigation>
    </Box>
  )
}

export default TopNavigationHeader
