import { IconButton, Layout, Text, TextField } from '@loadsmart/loadsmart-ui'
import { Themes } from '@loadsmart/loadsmart-ui/dist/theming'
import { Step, StepContent, StepIconProps, StepLabel, Stepper } from '@mui/material'
import Icon from 'atoms/Icon'
import clsx from 'clsx'
import ProcessorCollection from 'common/components/processor/ProcessorCollection'
import { RouteStep } from 'common/types/kraken-core/Routes'
import { isEmpty } from 'lodash'
import { useCallback, useEffect, useState } from 'react'

export interface RouteStepsProps {
  steps: Array<any>
  onChange?: (changed: any) => void
}

const RouteSteps = (props: RouteStepsProps) => {
  const [selectedStep, setSelectedStep] = useState<number>(-1)
  const [steps, setSteps] = useState<Array<RouteStep>>(props.steps)
  const [editableStepNames, setEditableStepNames] = useState<Map<number, boolean>>(new Map())
  const [editableStepName, setEditableStepName] = useState<string>()

  const markStepNameAsEditable = (index: number, value: boolean) => {
    setEditableStepNames(c => {
      const n = new Map()
      return n.set(index, value)
    })
  }

  const moveStepUp = useCallback(
    index => {
      if (index + 1 <= steps.length && index > 0) {
        const moveDown = steps[index - 1]
        const moveUp = steps[index]
        steps[index] = moveDown
        steps[index - 1] = moveUp
        const updated = [...steps]
        setSteps(updated)
        callbackUpdate(updated)
      }
    },
    [steps]
  )

  const moveStepDown = useCallback(
    index => {
      if (!steps) return
      if (index + 1 < steps.length) {
        const moveDown = steps[index]
        const moveUp = steps[index + 1]
        steps[index] = moveUp
        steps[index + 1] = moveDown
        const updated = [...steps]
        setSteps(updated)
        callbackUpdate(updated)
      }
    },
    [steps]
  )

  const callbackUpdate = useCallback(
    (changed: any) => {
      if (props.onChange) props.onChange(changed)
    },
    [props.onChange]
  )

  const updateStepName = useCallback(
    (index: number, name: string) => {
      const upadtedSteps = [...steps]
      upadtedSteps[index].name = name
      callbackUpdate(upadtedSteps)
    },
    [steps]
  )

  const toggleStep = useCallback(
    (stepIndex: number) => {
      if (selectedStep === stepIndex) {
        setSelectedStep(-1)
        return
      }
      setSelectedStep(stepIndex)
    },
    [selectedStep]
  )

  const handleStepProcessorsChange = useCallback(
    (index: number, step: RouteStep, changedProcessors: any) => {
      const updatedStep = {
        ...step,
        processors: changedProcessors,
      }
      steps[index] = updatedStep
      callbackUpdate(steps)
    },
    [steps, callbackUpdate]
  )

  const handleRemoveStep = useCallback(
    (index: number) => {
      let before: any = []
      if (index > 0) {
        before = steps.slice(0, index)
      }
      const after = steps.slice(index + 1)
      const updated = before.concat(after)
      callbackUpdate(updated)
    },
    [steps, callbackUpdate]
  )

  const handleAddNewStep = useCallback(() => {
    const updated = [...steps]
    updated.push({
      description: '',
      name: 'untitled',
      processors: [],
    })
    setSteps(updated)
    callbackUpdate(updated)
  }, [steps, callbackUpdate])

  const handleEditStep = useCallback(
    index => {
      toggleStep(index)
    },
    [steps, selectedStep]
  )

  useEffect(() => {
    setSteps(props.steps)
  }, [props.steps])

  useEffect(() => {
    setEditableStepNames(new Map())
  }, [selectedStep])

  function CustomStepIcon(props: StepIconProps) {
    const { active, className, icon } = props
    const style = {
      background: active ? Themes.Loadsmart['color-primary'] : Themes.Loadsmart['color-neutral'],
      borderRadius: '50%',
      height: '4em',
      width: '4em',
      marginLeft: '-1em',
    }
    return (
      <Layout.Group
        align="center"
        justify="center"
        style={style}
        className={clsx(className, 'cursor-pointer')}
        onClick={() => {
          toggleStep(Number(icon) - 1)
        }}
      >
        <Text variant="heading-md-bold" color="color-neutral-white">
          {icon}
        </Text>
      </Layout.Group>
    )
  }

  return (
    <Layout.Stack>
      {steps && isEmpty(steps) && (
        <Text variant="link" className="cursor-pointer" onClick={handleAddNewStep}>
          Add new step
        </Text>
      )}
      {steps && !isEmpty(steps) && (
        <Stepper orientation="vertical" activeStep={selectedStep} nonLinear>
          {steps.map((s: any, index) => {
            return (
              <Step key={`${s.name}_${index}`}>
                <StepLabel data-testid={`${s.name}_${index}`} StepIconComponent={CustomStepIcon}>
                  <Layout.Group
                    space="none"
                    className="pl-4"
                    align="center"
                    justify="space-between"
                  >
                    {editableStepNames.get(index) && (
                      <TextField
                        className="w-7/12"
                        value={editableStepName}
                        onChange={e => {
                          setEditableStepName(e.target.value)
                        }}
                        onBlur={() => {
                          markStepNameAsEditable(index, false)
                          if (editableStepName) updateStepName(index, editableStepName)
                        }}
                      />
                    )}
                    {!editableStepNames.get(index) && (
                      <Text
                        className="cursor-pointer"
                        variant={'heading-sm-bold'}
                        color={index === selectedStep ? 'color-primary' : 'color-neutral-dark'}
                        onClick={() => {
                          markStepNameAsEditable(index, true)
                          setEditableStepName(s.name)
                        }}
                      >
                        {s.name}
                      </Text>
                    )}
                    <Layout.Group align="center" justify="flex-end">
                      <IconButton
                        data-testid="edit-step"
                        onClick={() => handleEditStep(index)}
                        scale="large"
                      >
                        <Icon name="edit" size={20} />
                      </IconButton>
                      <IconButton
                        data-testid="move-step-down"
                        onClick={() => moveStepDown(index)}
                        scale="large"
                      >
                        <Icon name="arrow-corner-down" size={15} />
                      </IconButton>
                      <IconButton
                        data-testid="move-step-up"
                        onClick={() => moveStepUp(index)}
                        scale="large"
                      >
                        <Icon name="arrow-corner-up" size={15} />
                      </IconButton>
                      <IconButton
                        data-testid="remove-step"
                        onClick={() => {
                          handleRemoveStep(index)
                        }}
                        scale="large"
                      >
                        <Icon name="remove" size={20} />
                      </IconButton>
                    </Layout.Group>
                  </Layout.Group>
                </StepLabel>
                <StepContent>
                  <Layout.Stack className="p-2 w-full justify-start" data-testid="route-modal">
                    <Layout.Box padding="none" className="font-semibold">
                      Processors
                    </Layout.Box>
                    <ProcessorCollection
                      data={s?.processors}
                      onChange={(changedProcessors: any) => {
                        handleStepProcessorsChange(index, s, changedProcessors)
                      }}
                      title="Processors"
                    />
                  </Layout.Stack>
                </StepContent>

                {index + 1 === steps.length && (
                  <Layout.Box style={{ paddingLeft: 0, paddingTop: '1em' }}>
                    <Text variant="link" className="cursor-pointer" onClick={handleAddNewStep}>
                      Add new step
                    </Text>
                  </Layout.Box>
                )}
              </Step>
            )
          })}
        </Stepper>
      )}
    </Layout.Stack>
  )
}

export default RouteSteps
