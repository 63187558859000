import { Typography, Box } from '@mui/material'
import { TradingPartner } from 'common/types/kraken-core/TradingPartner'
import { MESSAGE_SEARCH_MAX_COUNT } from 'services/kraken-core/messages/messages.service'

export interface StackOfMessagesResultByPartnerProps {
  count: number
  tradingPartner: TradingPartner
}

export const StackOfMessagesResultByPartner = ({
  count,
  tradingPartner,
}: StackOfMessagesResultByPartnerProps) => {
  const postFix = count === 1 ? 'message' : 'messages'
  const prefix = count >= MESSAGE_SEARCH_MAX_COUNT ? '+' : ''
  return (
    <Box display="flex" justifyContent="start" width="100%">
      <Typography variant="body2" fontWeight={'bold'} mr={1} color={'primary'}>
        {prefix}
        {count}
      </Typography>
      <Typography variant="body2" mr={1}>
        {postFix} found for partner
      </Typography>
      <Typography variant="body2" fontWeight={'bold'} color={'primary'}>
        {tradingPartner.name}
      </Typography>
    </Box>
  )
}
