import { useEffect, useState } from 'react'
import { Grid, Tooltip, Typography } from '@mui/material'
import Icon from 'atoms/Icon'
import { Button, Dialog, Label, Layout, Select, Tag, TextField } from '@loadsmart/loadsmart-ui'
import DateHelper, { DATE_FORMAT_MMDDYYYYHHMMSS } from 'common/Date.helpers'
import { MessageRead } from 'common/types/kraken-core/Message'
import ClipboardLabel from 'atoms/ClipboardLabel'
import { Selectable } from '@loadsmart/loadsmart-ui/dist/hooks/useSelectable'
import Field from 'atoms/Field'
import EventLike from 'common/types/EventLike'
import { useSearchActions } from '../api'
import { useMessagesDashboardViewContext } from '../MessagesDashboardViewContext'
import analytics, { AnalyticsEvent, AnalyticsEventTrigger } from 'common/analytics'
import PartnershipTag from 'atoms/PartnershipTag/PartnershipTag'
import MessageStatusTag from '../common/MessageStatusTag'
import { isEmpty, capitalize } from 'lodash'
import { useMessagesDashboardContext } from '../MessageDahsboardContext'
export interface MessageSummaryProps {
  message: MessageRead
}

const STATUS_TAGS_COUNT = 3

function MessageSummary({ message }: MessageSummaryProps) {
  const dashboardContext = useMessagesDashboardContext()
  const context = useMessagesDashboardViewContext()
  const [isActionArgsModalOpened, setIsActionArgsModalOpened] = useState<boolean>(false)
  const [actionArgs, setActionArgs] = useState<Record<string, string>>({})
  const { data: actions, isSuccess } = useSearchActions()

  const partyReference = String(message.party_reference ?? '')
  const counterpartyReference = String(message.counterparty_reference ?? '')
  const createdAt = String(message.created_at ?? '')
  const updatedAt = String(message.updated_at ?? '')
  const deletedAt = String(message.deleted_at ?? '')
  const bodyContentType = String(message.content_type ?? '')

  useEffect(() => {
    context.selectMessages([message])
  }, [])

  const selectMessageAction = (event: any) => {
    // const action = Object.values(actions).find((a) => a.key === event.target.value?.value)
    const action = actions?.find(a => a.key === event.target.value?.value)

    if (!action) return

    context.selectMessageAction(action)
  }

  const executeMessageAction = () => {
    const messageArgs = context.messagesActionWapper.action?.definition.args
    if (messageArgs?.length) {
      setIsActionArgsModalOpened(true)
    } else {
      context.executeMessageAction()
    }
  }

  const actionsAsOptions = () => actions?.map(a => ({ label: a.name, value: a.key })) || []

  const DeliveredTag = () => {
    if (message.delivered) {
      return (
        <Tag size="large" variant="accent">
          Delivered
        </Tag>
      )
    }

    return (
      <Tag size="large" variant="danger">
        <span className="text-nowrap">Not Delivered</span>
      </Tag>
    )
  }

  const getTagDescription = (tagName: string) => {
    return (
      dashboardContext.messageDetails.messageStatusTags.find(to => to.tag === tagName)
        ?.description || ''
    )
  }

  return (
    <>
      <Dialog
        open={isActionArgsModalOpened}
        onOverlayClick={() => setIsActionArgsModalOpened(false)}
      >
        <Dialog.Header>Action arguments</Dialog.Header>
        <Dialog.Body>
          <Layout.Stack>
            {context.messagesActionWapper.action?.definition.args.map(arg => (
              <Field className="flex-1 w-full pb-4 lg:pb-0 lg:pr-4" key={arg.key}>
                <Label>{arg.description}</Label>
                <TextField
                  value={actionArgs[arg.key] || ''}
                  onChange={(e: EventLike<any>) => {
                    setActionArgs(prevState => ({
                      ...prevState,
                      [arg.key]: e.target.value,
                    }))
                  }}
                />
              </Field>
            ))}
          </Layout.Stack>
        </Dialog.Body>
        <Dialog.ActionConfirm onConfirm={() => context.executeMessageAction(actionArgs)} />
        <Dialog.ActionCancel onCancel={() => setIsActionArgsModalOpened(false)} />
      </Dialog>

      <Layout.Stack className="pl-4 pr-4">
        <Grid container className="pb-4" direction="row" spacing={0}>
          <Grid container direction="row" className="pb-4" spacing={2}>
            <Grid item xs={3} data-testid="message-summary-content-type">
              <Typography variant="caption">Content Type</Typography>
              <ClipboardLabel value={String(bodyContentType)} />
            </Grid>
            <Grid item xs={3}>
              <Typography variant="caption">Created at.</Typography>
              <ClipboardLabel
                value={String(DateHelper(createdAt)?.format(DATE_FORMAT_MMDDYYYYHHMMSS))}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography variant="caption">Updated at.</Typography>
              <ClipboardLabel
                value={String(DateHelper(updatedAt)?.format(DATE_FORMAT_MMDDYYYYHHMMSS))}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography variant="caption">Deleted at.</Typography>
              <ClipboardLabel
                value={
                  deletedAt ? String(DateHelper(deletedAt)?.format(DATE_FORMAT_MMDDYYYYHHMMSS)) : ''
                }
              />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={3} data-testid="message-summary-party">
              <Typography variant="caption">Party Reference</Typography>
              <ClipboardLabel value={partyReference} />
            </Grid>
            <Grid item xs={3} data-testid="message-summary-counterparty">
              <Typography variant="caption">Counter Party Reference</Typography>
              <ClipboardLabel value={counterpartyReference} />
            </Grid>
            <Grid item className="flex items-end justify-end">
              {message.partnership ? <PartnershipTag parntershipId={message.partnership} /> : null}
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Typography variant="h6">Actions</Typography>
        </Grid>
        <Layout.Group className="pb-4 flex-2" justify="space-between">
          <Layout.Group align="center" space="s">
            {isSuccess && (
              <Select
                id="select-action"
                name="select-action"
                options={actionsAsOptions()}
                onChange={event => {
                  analytics.event({
                    category: AnalyticsEvent.MessageDetailActionsSelect,
                    action: AnalyticsEventTrigger.select,
                    params: {
                      value: event.target.value,
                    },
                  })
                  selectMessageAction(event)
                }}
                value={
                  context.messagesActionWapper.action?.key
                    ? ({
                        label: context.messagesActionWapper.action?.name,
                        value: context.messagesActionWapper.action?.key,
                      } as Selectable)
                    : null
                }
              />
            )}

            <Button
              leading={<Icon name="bolt" className="flex ml-2" />}
              onClick={e => {
                analytics.event({
                  category: AnalyticsEvent.MessageDetailActionsApply,
                  action: AnalyticsEventTrigger.click,
                })
                executeMessageAction()
              }}
            />
          </Layout.Group>

          <Layout.Group align="center" justify="flex-end" space="s">
            <DeliveredTag />
            {message.status && !isEmpty(message.status) ? (
              <MessageStatusTag status={message.status} size="large" />
            ) : null}

            {message?.status_tags?.slice(0, STATUS_TAGS_COUNT - 1).map(st => {
              return (
                <Tooltip key={st} title={getTagDescription(st)}>
                  <div>
                    <Tag key={st} size="large">
                      {capitalize(st)}
                    </Tag>
                  </div>
                </Tooltip>
              )
            })}
          </Layout.Group>
        </Layout.Group>
      </Layout.Stack>
    </>
  )
}

export default MessageSummary
