import SearchResponse from 'common/types/SearchResponse'
import { ConversionCode } from 'common/types/kraken-core/ConversionCode'
import { UseMutationResult, UseQueryResult, useMutation, useQuery } from 'react-query'
import {
  createConversionCode,
  deleteConversionCode,
  getConversionCode,
  listConversionCode,
  updateConversionCode,
} from 'services/kraken-core/conversion_code/conversionCode.service'
import { UseSearchConversionCodesParams, UserGetConversionCode } from './types'

export const useGetConversionCode = (
  params: UserGetConversionCode,
  config: any
): UseQueryResult<ConversionCode> => {
  const queryFn = async (filters: UserGetConversionCode) => {
    const { response } = await getConversionCode(filters.id)
    return response?.data || {}
  }
  const query = useQuery(['conversion-codes/get', params], () => queryFn(params), { ...config })
  return query
}

export const useSearchConversionCodes = (
  params: UseSearchConversionCodesParams
): UseQueryResult<SearchResponse<ConversionCode[]>> => {
  const queryFn = async ({ page, ...filters }: UseSearchConversionCodesParams) => {
    const result = await listConversionCode({
      ...filters,
      source: filters.source?.id,
      target: filters.target?.id,
      page: page || 1,
      page_size: filters.page_size || 10,
    })
    return result.response?.data || []
  }

  const query = useQuery(['conversion-codes/search', params], () => queryFn(params), {
    keepPreviousData: true,
  })

  return query
}

export const useUpdateConversionCode = (): UseMutationResult<
  any,
  unknown,
  ConversionCode,
  unknown
> => {
  const mutation = useMutation(async (params: ConversionCode) => {
    const { response } = await updateConversionCode({ ...params })
    return response?.data
  })
  return mutation
}

export const useCreateConversionCode = (): UseMutationResult<
  any,
  unknown,
  ConversionCode,
  unknown
> => {
  const mutation = useMutation(async (params: ConversionCode) => {
    const { response } = await createConversionCode({ ...params })
    return response?.data
  })
  return mutation
}

export const useDeleteConversionCode = (): UseMutationResult<any, unknown, string, unknown> => {
  const mutation = useMutation(async (id: string) => {
    const { response } = await deleteConversionCode(id)
    return response?.data
  })
  return mutation
}
