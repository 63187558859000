import { Chip, Tooltip } from '@mui/material'

export interface TradingPartnerTagProps {
  id: string
  name: string
  label?: string
}

export default function TradingPartnerTag(props: TradingPartnerTagProps) {
  const handleClick = () => {
    window.open(`trading-partner/${props.id}`, '_blank')
  }

  const formattedName = String(props.name).toUpperCase()

  return (
    <Tooltip title={formattedName}>
      <Chip onClick={handleClick} onAuxClick={handleClick} label={formattedName} size="small" />
    </Tooltip>
  )
}
