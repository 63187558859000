import { ArrowBack, ArrowForward } from '@mui/icons-material'
import { Box, Drawer, Fab, Grid, Stack, useTheme } from '@mui/material'
import { Sidebar } from 'app/Sidebar/Sidebar'
import { TopNavigationHeader } from 'app/TopNavigationHeader'
import { TopNavigationContextWrapper } from 'app/TopNavigationHeader/TopNavigationContext'
import { ReactNode, useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import ContentComponentWrapper from './ContentWrapper'
import FallbackErrorPage from './FallbackErrorPage'
import EnvironmentBanner from './EnvBanner'
import { useAppContext } from 'hooks/useUserContext/appUserContext'
import GlobalSearchModal from 'molecules/GlobalSearchModal/GlobalSearchModal'
import analytics, { AnalyticsEvent, AnalyticsEventTrigger } from 'common/analytics'

export default function PrivatePage({ children }: { children: ReactNode }) {
  const theme = useTheme()
  const appContext = useAppContext()
  const drawerWidth = 215
  const drawerWidthWhenToggled = 70
  const [isDrawerOpen, setDrawerOpen] = useState<boolean>(appContext.userProperties?.sidebarIsOpen)

  const toggleDrawer = () => {
    const newValue = !isDrawerOpen

    analytics.event({
      category: AnalyticsEvent.SidebarToggle,
      action: AnalyticsEventTrigger.click,
      label: AnalyticsEvent.SidebarToggle,
    })

    setDrawerOpen(newValue)
    appContext.setUserProperties({ ...appContext.userProperties, sidebarIsOpen: newValue })
  }

  useEffect(() => {
    setDrawerOpen(appContext.userProperties?.sidebarIsOpen)
  }, [appContext.userProperties?.sidebarIsOpen])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
        appContext.setGlobalSearchOpen(prev => !prev)
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return (
    <TopNavigationContextWrapper>
      <Box sx={{ width: '100%', display: 'flex' }} flexDirection={'row'}>
        <Fab
          data-testid="toggle-sidebar-fab"
          size="small"
          sx={{
            position: 'fixed',
            zIndex: 1300,
            top: '3.5rem',
            left: isDrawerOpen ? '12rem' : '1rem',
            transition: 'left 0.3s',
          }}
          onClick={() => toggleDrawer()}
        >
          {isDrawerOpen ? <ArrowBack /> : <ArrowForward />}
        </Fab>

        {/* Toggled size sidebar menu */}
        <Drawer
          data-testid="toggled-sidebar"
          open={!isDrawerOpen}
          variant="permanent"
          color={theme.palette.mode === 'dark' ? 'primary' : 'secondary'}
          sx={{
            [`& .MuiDrawer-paper`]: {
              overflow: 'hidden',
              margin: 0,
              border: 0,
              backgroundColor: theme.palette.common.black,
            },
          }}
        >
          <Stack sx={{ height: '100%' }} spacing={2} padding={1}>
            <Grid container justifyContent="center">
              <Sidebar toggled={true} />
            </Grid>
          </Stack>
        </Drawer>

        {/* Full size sidebar menu */}
        <Drawer
          data-testid="full-sidebar"
          open={isDrawerOpen}
          variant="persistent"
          sx={{
            width: isDrawerOpen ? drawerWidth : drawerWidthWhenToggled,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              overflow: 'hidden',
              margin: 0,
              border: 0,
              backgroundColor: theme.palette.common.black,
            },
          }}
        >
          <Stack sx={{ position: 'static', height: '100%' }} alignItems={'center'}>
            <EnvironmentBanner />
            <Sidebar toggled={!isDrawerOpen} />
          </Stack>
        </Drawer>

        <GlobalSearchModal
          open={appContext.isGlobalSearchOpen}
          onClose={() => {
            appContext.setGlobalSearchOpen(false)
          }}
        />

        {/* Main content */}
        <Stack sx={{ width: '100%', overflowX: 'auto' }}>
          <TopNavigationHeader />
          <ErrorBoundary FallbackComponent={FallbackErrorPage}>
            <ContentComponentWrapper>{children}</ContentComponentWrapper>
          </ErrorBoundary>
        </Stack>
      </Box>
    </TopNavigationContextWrapper>
  )
}
