import { Layout, Text } from '@loadsmart/loadsmart-ui'
import LSDate, { DATE_FORMAT_MMDDYYHHMMSS, DATE_FORMAT_MMDDYYYYHHMMSS } from 'common/Date.helpers'
import { DateTimezoneLabelProps } from './types'

import './DateTimezoneLabel.css'
import { Tooltip } from '@mui/material'

function DateTimezoneLabel({ className, date }: DateTimezoneLabelProps) {
  if (!date) return <Layout.Box>-</Layout.Box>

  return (
    <Tooltip
      className={className}
      title={
        <Layout.Stack space="none">
          <Text color="color-neutral-white">
            UTC: {LSDate(date)?.value().utc().format(DATE_FORMAT_MMDDYYYYHHMMSS)}
          </Text>
          <Text color="color-neutral-white">
            GMT-3: {LSDate(date)?.value().utcOffset('-0300').format(DATE_FORMAT_MMDDYYYYHHMMSS)}
          </Text>
          <Text color="color-neutral-white">
            GMT-5: {LSDate(date)?.value().utcOffset('-0500').format(DATE_FORMAT_MMDDYYYYHHMMSS)}
          </Text>
        </Layout.Stack>
      }
    >
      <Text className="date-timezone-label" color="color-neutral-dark" variant="caption">
        {LSDate(date)?.format(DATE_FORMAT_MMDDYYHHMMSS)}
      </Text>
    </Tooltip>
  )
}

export default DateTimezoneLabel
