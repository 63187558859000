import { AxiosRequestConfig } from 'axios'
import { to } from 'common/helpers/awaitTo'
import { get, patch, post } from 'common/HTTPClient'
import { DefaultHTTPResponse } from 'common/HTTPClient/DefaultHTTPResponse'
import {
  IntegrationOnboardConfirmationResponse,
  IntegrationTemplate,
} from 'common/types/kraken-core/IntegrationTemplate'
import { isEmpty } from 'lodash'

export interface SearchTemplateFilters {
  property?: string
  value?: string
}

export interface SearchTemplatesResponse {
  templates: Array<IntegrationTemplate>
}

export interface UpdateTemplateReponse {}

export interface GetTemplateReponse {
  template: IntegrationTemplate
}

export interface ConfirmIntegrationOnboarding {
  template: IntegrationTemplate
  data: any
}

export async function searchTemplates(
  filters?: Array<SearchTemplateFilters>,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<SearchTemplatesResponse>> {
  let error = null
  let response = null

  const params: any = {}

  for (const filter of filters || []) {
    if (filter.property && !isEmpty(filter.value)) {
      params[filter?.property] = filter.value
    }
  }
  ;[error, response] = await to(get('/onboarding/templates', { ...config, params }))
  return {
    error,
    response,
  }
}

export async function getTemplate(
  templateId: string
): Promise<DefaultHTTPResponse<GetTemplateReponse>> {
  const [error, response] = await to(get(`/onboarding/templates/${templateId}`))

  return {
    error,
    response,
  }
}

export async function confirmIntegrationOnboard(
  confirmation: ConfirmIntegrationOnboarding,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<IntegrationOnboardConfirmationResponse>> {
  const url = '/onboarding/onboard'
  const data = {
    integration_template_id: confirmation.template.id,
    parameters: confirmation.data,
  }

  const [error, response] = await to(
    post(url, data, {
      ...config,
    })
  )

  return {
    error,
    response,
  }
}

export async function saveTemplate(
  template: IntegrationTemplate
): Promise<DefaultHTTPResponse<UpdateTemplateReponse>> {
  const [error, response] = await to(
    patch(`/onboarding/templates/${template.id}`, {
      key: template.key,
      ui_schema: template.ui_schema || null,
      schema: template.schema || null,
    })
  )
  return {
    error,
    response,
  }
}
