import { Typography, Box } from '@mui/material'

export interface StackOfMessagesResultProps {
  count: number
  referenceQuery: string
}

export const StackOfMessagesResultByReference = ({
  count,
  referenceQuery,
}: StackOfMessagesResultProps) => {
  const postFix = count === 1 ? 'message' : 'messages'
  const prefix = '+'
  return (
    <Box display="flex" justifyContent="start" width="100%">
      <Typography variant="body2" fontWeight={'bold'} mr={1} color={'primary'}>
        {prefix}
        {count}
      </Typography>
      <Typography variant="body2" mr={1}>
        {postFix} found containing reference
      </Typography>
      <Typography variant="body2" fontWeight={'bold'} color={'primary'}>
        {referenceQuery}
      </Typography>
    </Box>
  )
}
