import { Card, Layout, Switch, Text, TextField, ToggleGroup } from '@loadsmart/loadsmart-ui'
import { ToggleGroupOptionProps } from '@loadsmart/loadsmart-ui/dist/components/ToggleGroup/ToggleGroup.types'
import LSDate, { DATE_FORMAT_MMDDYYYYHHMM } from 'common/Date.helpers'
import isJson from 'common/helpers/isJSON'
import { RouteRead, RouteStep } from 'common/types/kraken-core/Routes'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import RouteSteps from './RouteStepViews/RouteSteps'
import RouteStepsRaw from './RouteStepViews/RouteStepsRaw'
import IaCComponentReferenceWarning from 'atoms/IaCComponentReferenceWarning/IaCComponentReferenceWarning'

export interface RouteDetailFormProps {
  route: RouteRead
  onChange: (route: RouteRead) => void
}

const EXECUTION_OPTIONS: Array<ToggleGroupOptionProps> = [
  {
    value: 'sync',
    label: 'Sync',
  },
  {
    value: 'async',
    label: 'Async',
  },
  {
    value: 'async-hybrid',
    label: 'Async-Hybrid',
  },
]

const RouteDetailForm = ({ route, onChange }: RouteDetailFormProps) => {
  const [steps, setSteps] = useState<Array<RouteStep>>([])
  const [stepsRawView, setStepsRawView] = useState<boolean>(false)
  const [model, setModel] = useState<RouteRead>()
  const handleRawStepsChanged = useCallback(
    (changed: string) => {
      if (isJson(changed)) {
        setSteps(JSON.parse(changed))
      }
    },
    [steps]
  )

  const handleStepperStepsChanged = useCallback(
    (changed: object) => {
      if (changed) {
        setSteps(changed as any)
      }
    },
    [steps]
  )

  const handleFieldChange = (e: ChangeEvent<any>) => {
    if (model) {
      const key = e.target.name
      const value = e.target.value
      const nesetedFields: any = key.split('_')
      if (nesetedFields.length > 1) {
        setModel((s: any) => {
          return {
            ...s,
            [nesetedFields[0]]: {
              ...s[nesetedFields[0]],
              [nesetedFields[1]]: value,
            },
          }
        })
      } else {
        setModel((s: any) => {
          return {
            ...s,
            [e.target.name]: value,
          }
        })
      }
    }
  }

  useEffect(() => {
    if (route) {
      setModel(route)
      setSteps(route.definition.steps)
    }
  }, [route])

  useEffect(() => {
    if (steps && model) {
      setModel({
        ...model,
        definition: {
          ...model.definition,
          steps: steps,
        },
      })
    }
  }, [steps])

  useEffect(() => {
    if (model && onChange) onChange(model)
  }, [model])

  const Steps = useMemo(() => {
    return (
      <Card>
        <Card.Body>
          {steps && (
            <Layout.Stack className="p-4">
              <Text variant="heading-sm-bold">Steps</Text>
              <Layout.Group align="center">
                <Text variant="caption-bold">Raw view?</Text>
                <Switch active={stepsRawView} onToggle={value => setStepsRawView(!stepsRawView)} />
              </Layout.Group>
              {!stepsRawView && <RouteSteps steps={steps} onChange={handleStepperStepsChanged} />}
              {stepsRawView && <RouteStepsRaw steps={steps} onChange={handleRawStepsChanged} />}
              <Layout.Stack />
            </Layout.Stack>
          )}
        </Card.Body>
      </Card>
    )
  }, [steps, stepsRawView])

  return (
    <Layout.Stack>
      <Card>
        <Card.Body>
          <Layout.Stack space="s">
            {route?.iac_reference ? <IaCComponentReferenceWarning /> : null}
            {route?.id && (
              <Layout.Group justify="space-between">
                <Layout.Stack>
                  <Text variant="caption-bold" color="color-neutral">
                    ID
                  </Text>
                  <Text variant="heading-sm-bold">{route?.id}</Text>
                </Layout.Stack>
                <Layout.Stack>
                  <Text variant="caption-bold" color="color-neutral">
                    Created at
                  </Text>
                  <Text variant="heading-sm-bold">
                    {LSDate(route?.created_at)?.format(DATE_FORMAT_MMDDYYYYHHMM)}
                  </Text>
                </Layout.Stack>
                <Layout.Stack>
                  <Text variant="caption-bold" color="color-neutral">
                    Updated at
                  </Text>
                  <Text variant="heading-sm-bold">
                    {LSDate(route?.updated_at)?.format(DATE_FORMAT_MMDDYYYYHHMM)}
                  </Text>
                </Layout.Stack>
                <Layout.Stack>
                  <Text variant="caption-bold" color="color-neutral">
                    Deleted at
                  </Text>
                  <Text variant="heading-sm-bold">
                    {LSDate(route?.deleted_at)?.format(DATE_FORMAT_MMDDYYYYHHMM)}
                  </Text>
                </Layout.Stack>
              </Layout.Group>
            )}

            <Layout.Group space="s">
              <Layout.Stack
                data-testid="route-code"
                space="s"
                className="w-full"
                style={{
                  maxWidth: '50%',
                }}
              >
                <Text>Code</Text>
                <TextField name="code" value={model?.code} onChange={handleFieldChange} />
              </Layout.Stack>

              <Layout.Stack
                data-testid="route-queue"
                space="s"
                className="w-full"
                style={{
                  maxWidth: '30%',
                }}
              >
                <Text>Queue</Text>
                <TextField
                  name="definition_queue"
                  value={model?.definition.queue}
                  onChange={handleFieldChange}
                />
              </Layout.Stack>
              <Layout.Stack
                data-testid="route-code"
                style={{
                  maxWidth: '30em',
                }}
                space="s"
              >
                <Text>Execution</Text>
                <ToggleGroup
                  name="definition_execution"
                  aria-label={model?.definition.execution}
                  options={EXECUTION_OPTIONS}
                  value={model?.definition.execution}
                  onChange={e => {
                    handleFieldChange(e as any)
                  }}
                />
              </Layout.Stack>
            </Layout.Group>

            <Layout.Stack data-testid="route-code" space="s">
              <Text>Description</Text>
              <TextField
                name="description"
                value={model?.description}
                onChange={handleFieldChange}
              />
            </Layout.Stack>
          </Layout.Stack>
        </Card.Body>
      </Card>
      {Steps}
    </Layout.Stack>
  )
}

export default RouteDetailForm
