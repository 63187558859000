import { Layout, LoadingDots, Tag, Text, Tooltip } from '@loadsmart/loadsmart-ui'
import { People } from '@mui/icons-material'
import { useGetPartnership } from 'pages/Partnership/api'
import { useEffect, useState } from 'react'

export interface PartnershipTagProps {
  parntershipId?: number
}

interface PartnershipTagState {
  partyName: string | undefined
  counterPartyName: string | undefined
}

export default function PartnershipTag(props: PartnershipTagProps) {
  const [state, setState] = useState<PartnershipTagState>({
    partyName: undefined,
    counterPartyName: undefined,
  })

  const { data, refetch, isLoading } = useGetPartnership(
    {
      id: props.parntershipId as any,
    },
    {
      enabled: Boolean(props.parntershipId),
    }
  )

  const handleOpenPartnership = () => {
    window.open(`/partnership/${props.parntershipId}`, '_blank')
  }

  useEffect(() => {
    if (!data) {
      return
    }

    setState({
      partyName: String(data.party?.name).toUpperCase().slice(0, 20),
      counterPartyName: String(data.counterparty?.name).toUpperCase().slice(0, 20),
    })
  }, [data])

  useEffect(() => {
    refetch()
  }, [props.parntershipId])

  if (!data || !props.parntershipId) {
    return null
  }

  return (
    <Tooltip message={`Partnership`}>
      <Tag
        size="large"
        className="cursor-pointer"
        onClick={e => {
          e.preventDefault()
          handleOpenPartnership()
        }}
      >
        {isLoading ? (
          <LoadingDots />
        ) : (
          <Layout.Group align="center" justify="center" className="p-4">
            <Text variant="chips-sm">{state.partyName}</Text>
            <People />
            <Text variant="chips-sm">{state.counterPartyName}</Text>
          </Layout.Group>
        )}
      </Tag>
    </Tooltip>
  )
}
