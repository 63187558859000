import { useEffect, useRef, useState } from 'react'
import { DataModelSchemaDetail } from 'common/types/kraken-core/DataModelSchema'
import { Breadcrumbs, Button, Card, Layout, LoadingDots, Tabs } from '@loadsmart/loadsmart-ui'
import { useTabTitle } from 'hooks/useTabTitle/useTabTitle'
import useTopNavigationContext from 'hooks/useTopNavigationContext/useTopNavigationContext'
import DataModelSchemaValidation from '../components/DataModelSchemaValidation'
import DataModelSchemaStructure from '../components/DataModelSchemaStructure'
import { useCreateDataModelSchema, useGetDataModelSchema, useUpdateDataModelSchema } from '../api'
import DataModelSchemaForm from '../components/DataModelSchemaForm'
import { useHistory, useParams } from 'react-router'
import { toast } from 'react-toastify'
import isEmpty from 'lodash.isempty'

export enum DataModelSchemaDetailMode {
  CREATE = 'create',
  UPDATE = 'update',
}

export interface DataModelSchemaDetailsProps {
  id?: string
  mode: DataModelSchemaDetailMode
}

export default function DataModelSchemaDetails(props: DataModelSchemaDetailsProps) {
  const changeTabTitle = useTabTitle('')
  const history = useHistory()
  const { id } = useParams<DataModelSchemaDetailsProps>()

  const [mode, setMode] = useState<DataModelSchemaDetailMode>(props.mode)
  const [model, setModel] = useState<DataModelSchemaDetail>()
  const { data, isLoading } = useGetDataModelSchema(id || (props.id as any), {
    enabled: Boolean(id) && mode === DataModelSchemaDetailMode.UPDATE,
  })
  const {
    mutate: update,
    isSuccess: didSuccessToUpdate,
    isError: didFailtToUpdate,
    error: updateError,
  } = useUpdateDataModelSchema()
  const {
    mutate: create,
    isSuccess: didSuccessToCreate,
    isError: didFailtToCreate,
    error: createError,
    data: createResponse,
  } = useCreateDataModelSchema()

  const topNavigation = useTopNavigationContext()
  const handleSave = useRef<Function>(() => {})

  const handleOnChange = (changed: DataModelSchemaDetail) => {
    setModel(changed)
  }

  const handleOnStructure = (value: string) => {
    if (model)
      setModel(c => {
        if (c) {
          return {
            ...c,
            structure: value,
          }
        }
      })
  }

  handleSave.current = async () => {
    if (!model) {
      toast.error('Failure when saving')
      return
    }

    if (isEmpty(model)) {
      toast.error('Provide the required fields')
      return
    }

    const mandatoryFields = ['engine', 'structure', 'description']
    const missingFields = mandatoryFields.filter((f: any) => isEmpty((model as any)[f]))
    if (missingFields.length > 0) {
      toast.error(`Field(s) ${missingFields.join(' & ')} are mandatory`)
      return
    }

    switch (mode) {
      case DataModelSchemaDetailMode.CREATE:
        create(model)
        break
      case DataModelSchemaDetailMode.UPDATE:
        update(model)
        break
      default:
        toast.error(`There no action for the current mode: ${mode}`)
        break
    }
  }

  useEffect(() => {
    setModel(data)
  }, [data])

  useEffect(() => {
    if (model)
      topNavigation.updateState({
        children: (
          <Layout.Group align="center" className="flex-1">
            <Layout.Box padding="none" className="flex-1">
              <Breadcrumbs
                entries={[
                  {
                    label: 'Data Models',
                    active: true,
                    url: '#',
                    onClick: () => {
                      history.push('/data-model/schemas')
                    },
                  },
                  {
                    label: `${model.description ?? ''}`,
                    active: true,
                  },
                ]}
              />
            </Layout.Box>
            <Button
              variant="primary"
              // disabled={!isDirty || isUpdating}
              onClick={() => handleSave.current()}
            >
              Save
            </Button>
          </Layout.Group>
        ),
      })
  }, [model])

  useEffect(() => {
    if (mode === DataModelSchemaDetailMode.CREATE) {
      setModel({})
    }
    changeTabTitle(`Data Model - ${mode}`)
  }, [mode])

  useEffect(() => {
    if (didFailtToCreate || didFailtToUpdate) {
      toast.error('Something fail when creating')
      return
    }

    if (didSuccessToCreate || didSuccessToUpdate) {
      toast.success('Successfully saved')
      if (didSuccessToCreate) {
        setMode(DataModelSchemaDetailMode.UPDATE)
        setModel(createResponse)
      }
    }
  }, [didSuccessToCreate, didFailtToCreate, didFailtToUpdate, didSuccessToUpdate])

  return (
    <Card>
      <Card.Body>
        {isLoading || !model ? <LoadingDots /> : null}

        {!isLoading && model ? (
          <Layout.Stack space="l">
            <DataModelSchemaForm object={model} onChange={handleOnChange} />
            <Tabs>
              <Tabs.Items>
                <Tabs.Item name="schema" default>
                  Schema
                </Tabs.Item>

                {/* Only availble when there's no pending changes */}
                <Tabs.Item
                  name="validation"
                  disabled={!model.id || mode === DataModelSchemaDetailMode.CREATE}
                >
                  Validation
                </Tabs.Item>
              </Tabs.Items>
              <Tabs.Panels>
                <Tabs.Panel name="schema">
                  <DataModelSchemaStructure
                    contentType={model.engine || 'application/json'}
                    value={model.structure}
                    onChange={handleOnStructure}
                  />
                </Tabs.Panel>
                {model.id ? (
                  <Tabs.Panel name="validation">
                    <DataModelSchemaValidation
                      dataModelSchemaId={model.id}
                      value={model.structure}
                      onChange={handleOnStructure}
                    />
                  </Tabs.Panel>
                ) : null}
              </Tabs.Panels>
            </Tabs>
          </Layout.Stack>
        ) : null}
      </Card.Body>
    </Card>
  )
}
