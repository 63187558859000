import { SearchMessagesFilters } from 'services/kraken-core/messages/messages.service'
import moment from 'moment'

export const defaultFieldsValues = {
  created_at_from: moment().subtract(1, 'days').startOf('day').toISOString(),
  created_at_to: undefined,
  updated_at_from: undefined,
  updated_at_to: undefined,
  direction: undefined,
  partner: [],
  party_id: undefined,
  counterparty_id: undefined,
  reference_code: '',
  transaction_type: [],
  exclude_transaction_type: [],
  delivered: undefined,
  last_step: '',
  exclude_last_step: '',
  status: [],
  status_tags: [],
  installation: [],
  integration: [],
  pair_of_partners: undefined,
}

export function convertFiltersToSearchableFilters(filters: any): SearchMessagesFilters {
  let partnerIds
  if (filters.fields.partner) {
    partnerIds = Array.isArray(filters.fields.partner)
      ? filters.fields.partner.map((p: any) => p.id).join(',')
      : filters.fields.partner.id
  }

  let transactionType
  if (filters.fields.transaction_type) {
    transactionType = Array.isArray(filters.fields.transaction_type)
      ? filters.fields.transaction_type.map((p: any) => p.key).join(',')
      : filters.fields.transaction_type.key
  }

  let excludeTransactionType
  if (filters.fields.exclude_transaction_type) {
    excludeTransactionType = Array.isArray(filters.fields.exclude_transaction_type)
      ? filters.fields.exclude_transaction_type.map((p: any) => p.key).join(',')
      : filters.fields.exclude_transaction_type.key
  }

  let statuses
  if (filters.fields.status) {
    statuses = Array.isArray(filters.fields.status)
      ? filters.fields.status.map((p: any) => p.status).join(',')
      : [filters.fields.status]
  }

  let status_tags
  if (filters.fields.status_tags) {
    status_tags = Array.isArray(filters.fields.status_tags)
      ? filters.fields.status_tags.join(',')
      : [filters.fields.status_tags]
  }

  let installationIds
  if (filters.fields.installation) {
    installationIds = Array.isArray(filters.fields.installation)
      ? filters.fields.installation.map((p: any) => p.id).join(',')
      : [filters.fields.installation.id]
  }

  let integrationIds
  if (filters.fields.integration) {
    integrationIds = Array.isArray(filters.fields.integration)
      ? filters.fields.integration.map((p: any) => p.id).join(',')
      : [filters.fields.integration.id]
  }

  const referenceCode: string = filters.fields.reference_code ?? ''
  const reference = referenceCode.length > 0 ? referenceCode : undefined

  let partyId
  if (filters.fields.party_id) {
    partyId = filters.fields.party_id.id
  }

  let counterpartyId
  if (filters.fields.counterparty_id) {
    counterpartyId = filters.fields.counterparty_id.id
  }

  let pair_of_partners
  if (filters.fields.pair_of_partners) {
    pair_of_partners = Boolean(filters.fields.pair_of_partners)
  }

  const searchFilters: SearchMessagesFilters = {
    direction: filters.fields.direction,
    delivered: filters.fields.delivered,
    last_step: filters.fields.last_step,
    exclude_last_step: filters.fields.exclude_last_step,
    installationId: installationIds,
    integrationId: integrationIds,
    partnerId: partnerIds,
    partyId: partyId,
    counterpartyId: counterpartyId,
    reference,
    createdDateStart: filters.fields.created_at_from,
    createdDateEnd: filters.fields.created_at_to,
    updatedDateStart: filters.fields.updated_at_from,
    updatedDateEnd: filters.fields.updated_at_to,
    transactionType,
    excludeTransactionType,
    status: statuses,
    status_tags: status_tags,
    pair_of_partners: pair_of_partners,
  }
  return searchFilters
}
