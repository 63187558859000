import {
  Button,
  BannerLarge,
  Card,
  Drawer,
  Dropdown,
  IconButton,
  Layout,
  LoadingDots,
  Switch,
  Tag,
  Text,
  Label,
} from '@loadsmart/loadsmart-ui'
import { Avatar, Grow } from '@mui/material'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Icon from 'atoms/Icon'
import isJson from 'common/helpers/isJSON'
import ErrorPanel from 'molecules/ErrorPanel'
import FileViewer from 'molecules/FileViewer/FileViewer'
import JsonForm, { defaultsAjv } from 'molecules/JsonForm/JsonForm'
import { toast } from 'atoms/Toast'
import { useCallback, useEffect, useRef, useState } from 'react'
import {
  getProcessor,
  getProcessorReferences,
} from 'services/kraken-core/processors/processors.service'
import styled from 'styled-components'
import schemas from './schema'

export interface ProcessorItemProps {
  index: number
  name: string
  formattedName: string
  summary: string
  definition: any
  onRemoveProcessor: (index: number) => void
  onUpdateProcessor: (index: number, value: any) => void
  onReorderDown: (index: number) => void
  onReorderUp: (index: number) => void
}

interface EnrichedProcessor {
  index: number
  name: string
  formattedName: string
  doc?: string
  schema?: any
  uiSchema?: any
  definition: any
}

interface ProcessorReference {
  reference: string
  uuid: string
  name: string
  processor_definition: any
}

const StyleDrawer = styled(Drawer)`
  width: 80rem;
`

const StyledCard = styled(Card)`
  &:hover {
    background-color: whitesmoke;
  }
`

function ProcessorItem(props: ProcessorItemProps) {
  const rawDefinition = useRef<object>()
  const [rawView, setRawView] = useState(false)
  const [showDocumentation, setShowDocumentation] = useState(false)
  const [enrichedProcessor, setEnrichedProcessor] = useState<EnrichedProcessor>()
  const [isDetailsExpanded, toggleDetails] = useState<boolean>(false)
  const [isLoadingProcessorDetails, toggleLoadingProcessorDetails] = useState<boolean>()
  const [validationErrors, setValidationErrors] = useState<string>()
  const [errors, setErrors] = useState<any>()
  const [processorReferences, setProcessorReferences] = useState<Array<ProcessorReference>>()

  const saveProcessorDetails = () => {
    try {
      if (enrichedProcessor) {
        props.onUpdateProcessor(enrichedProcessor.index, {
          [enrichedProcessor.name]: rawDefinition.current,
        })
        toggleDetails(false)
        toast.success(`Processor saved!`)
      }
    } catch (error) {
      toast.error(`Couldn't save processor: ${error}`)
    }
  }

  const getProcessorDetails = useCallback(async () => {
    setErrors(undefined)
    toggleLoadingProcessorDetails(true)
    const queryFn = async (name: string) => getProcessor(name)

    const { response, error } = await queryFn(props.name)
    console.log(error)
    if (error) {
      toggleLoadingProcessorDetails(false)
      setErrors(error)
      return
    }

    const details = response?.data
    const schema = details.schema ? JSON.parse(details.schema) : {}
    const localSchema = schemas[props.name]
    const uiSchema = localSchema ? localSchema.ui : undefined

    setEnrichedProcessor({
      formattedName: props.formattedName,
      index: props.index,
      name: props.name,
      doc: details.doc,
      schema,
      uiSchema,
      definition: props.definition[props.name],
    })
    toggleLoadingProcessorDetails(false)

    getReferences()
  }, [props])

  const getReferences = useCallback(async () => {
    setErrors(undefined)

    const queryFn = async (name: string) => getProcessorReferences(name)

    const { response, error } = await queryFn(props.name)
    console.log(error)
    if (error) {
      toggleLoadingProcessorDetails(false)
      setErrors(error)
      return
    }

    const references =
      response?.data.references && Array.isArray(response?.data.references)
        ? response?.data.references
        : []
    setProcessorReferences(references)
  }, [props])

  const toggleDocumentation = () => {
    setShowDocumentation(p => !p)
  }

  const areTherePendingChanges = () => {
    return props.definition[props.name] !== rawDefinition.current
  }

  useEffect(() => {
    if (!rawView) {
      setEnrichedProcessor((p: any) => {
        return {
          ...p,
          definition: rawDefinition.current,
        }
      })
    }
  }, [rawView])

  return (
    <div data-testid={`processor-item-${props.index}`}>
      <StyleDrawer
        data-testid="processor-drawer"
        open={isDetailsExpanded}
        onClose={() => toggleDetails(false)}
        className="drawer"
      >
        <Drawer.Header data-testid="processor-drawer-header">
          <Layout.Stack justify="space-between" className="w-full">
            <Layout.Group align="center" justify="space-between">
              <Avatar data-testid="processor-drawer-header-avatar">
                {String(props.index + 1)}
              </Avatar>
              <Layout.Group data-testid="processor-drawer-header-name" align="center">
                {enrichedProcessor?.formattedName}
              </Layout.Group>
              <Layout.Group align="center">
                <Dropdown data-testid="processor-drawer-dropdown-button-details">
                  <Dropdown.Trigger onClick={toggleDocumentation}>
                    {showDocumentation ? 'Hide Details' : 'Show Details'}
                  </Dropdown.Trigger>
                </Dropdown>

                <Button
                  data-testid="processor-drawer-button-cancel"
                  className="flex-end"
                  variant="secondary"
                  onClick={() => toggleDetails(false)}
                >
                  Cancel
                </Button>
                <Button
                  data-testid="processor-drawer-button-save"
                  className="flex-end"
                  variant="primary"
                  onClick={() => saveProcessorDetails()}
                >
                  Save
                </Button>
              </Layout.Group>
            </Layout.Group>
            {showDocumentation ? (
              <Layout.Stack align="flex-start" className="w-full">
                <BannerLarge description={enrichedProcessor?.doc} className="w-full" />

                <Text>
                  Check bellow <Tag variant="accent">{processorReferences?.length}</Tag> references
                  on how to use this processor:
                </Text>

                <Layout.Stack space="s" className="w-full">
                  <div>
                    {processorReferences?.map(reference => (
                      <Accordion key={`processor_item_${reference.uuid}`}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Text>
                            {reference.reference}: {reference.name} ({reference.uuid}){' '}
                          </Text>
                        </AccordionSummary>

                        <AccordionDetails>
                          <Layout.Stack>
                            <Layout.Stack>
                              <Label>Definition:</Label>
                              <FileViewer
                                content={JSON.stringify(reference.processor_definition, null, 2)}
                                contentType="application/json"
                                showOptions={false}
                                options={{
                                  heigth: window.innerHeight * 0.2,
                                  readonly: true,
                                }}
                              />
                            </Layout.Stack>
                          </Layout.Stack>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </div>
                </Layout.Stack>
              </Layout.Stack>
            ) : null}
          </Layout.Stack>
        </Drawer.Header>

        <Drawer.Body data-testid="processor-drawer-body" className="h-full p-0">
          <Layout.Stack className="w-full flex">
            {errors && (
              <ErrorPanel
                data-testid="processor-drawer-error-panel"
                title="Failure"
                error={String(errors)}
              />
            )}
            {isLoadingProcessorDetails && <LoadingDots />}
            {!isLoadingProcessorDetails && enrichedProcessor && (
              <Layout.Stack>
                {validationErrors && (
                  <BannerLarge
                    data-testid="processor-drawer-validation-errors"
                    variant="danger"
                    title="Definition validation error"
                    description={String(validationErrors)}
                  />
                )}

                <Layout.Group>
                  <Text data-testid="processor-drawer-raw-view-text">Raw view?</Text>
                  <Switch onToggle={() => setRawView(p => !p)} active={rawView} />
                  {areTherePendingChanges() ? <Tag variant="warning">PENDING CHANGES</Tag> : null}
                </Layout.Group>

                {!rawView ? (
                  <JsonForm
                    data={enrichedProcessor.definition}
                    onChange={value => {
                      if (value.errors && value.errors.length > 0) {
                        const formatedErrors = defaultsAjv.errorsText(value.errors, {
                          dataVar: '',
                          separator: ' | ',
                        })
                        setValidationErrors(formatedErrors)
                      } else {
                        setValidationErrors(undefined)
                      }

                      // setLocalProcessorDefinition(value.data)
                      rawDefinition.current = value.data
                      setEnrichedProcessor((p: any) => {
                        return {
                          ...p,
                          definition: value.data,
                        }
                      })
                    }}
                    schema={enrichedProcessor.schema}
                    uiSchema={enrichedProcessor.uiSchema}
                  />
                ) : (
                  <FileViewer
                    content={JSON.stringify(enrichedProcessor.definition, null, 2)}
                    contentType="application/json"
                    options={{
                      heigth: window.innerHeight * 0.7,
                    }}
                    onChange={(value: string) => {
                      if (isJson(value)) {
                        const definition = JSON.parse(value)
                        // setLocalProcessorDefinition(definition)
                        rawDefinition.current = definition
                      }
                    }}
                  />
                )}
              </Layout.Stack>
            )}
          </Layout.Stack>
        </Drawer.Body>
      </StyleDrawer>

      <Grow in>
        <div>
          <StyledCard
            data-testid={`processor-card-item-${props.index}`}
            className="card"
            onClick={() => {
              toggleDetails(true)
              getProcessorDetails()
            }}
            flagged
            role="button"
          >
            <Card.Body>
              <Layout.Group align="center">
                <Layout.Group align="center">
                  <Avatar data-testid="processor-card-avatar">{String(props.index + 1)}</Avatar>

                  <IconButton
                    data-testid="processor-card-button-remove"
                    variant="icon"
                    onClick={event => {
                      props.onRemoveProcessor(props.index)
                      event.stopPropagation()
                    }}
                    scale="large"
                  >
                    <Icon name="remove" />
                  </IconButton>
                  <IconButton
                    data-testid="processor-card-button-move-up"
                    variant="icon"
                    onClick={event => {
                      props.onReorderUp(props.index)
                      event.stopPropagation()
                    }}
                    scale="large"
                  >
                    <Icon name="arrow-corner-up" />
                  </IconButton>
                  <IconButton
                    data-testid="processor-card-button-move-down"
                    variant="icon"
                    onClick={event => {
                      props.onReorderDown(props.index)
                      event.stopPropagation()
                    }}
                    scale="large"
                  >
                    <Icon name="arrow-corner-down" />
                  </IconButton>
                  <Tag data-testid="processor-card-formatted-name" size="large" variant="accent">
                    {props.formattedName}
                  </Tag>
                  <div data-testid="processor-card-summary">{props.summary}</div>
                </Layout.Group>
              </Layout.Group>
            </Card.Body>
          </StyledCard>
        </div>
      </Grow>
    </div>
  )
}

export default ProcessorItem
