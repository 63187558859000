import {
  Button,
  Card,
  Dropdown,
  Label,
  Layout,
  LoadingDots,
  Select,
  Tag,
  Text,
  TextField,
} from '@loadsmart/loadsmart-ui'
import { useDeleteConversionCode, useSearchConversionCodes } from './api'
import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { ConversionCode } from 'common/types/kraken-core/ConversionCode'
import Icon from 'atoms/Icon/Icon'
import { useEffect, useState } from 'react'
import isEmpty from 'lodash.isempty'
import CodeBlock from 'atoms/CodeBlock/CodeBlock'
import { onSearchTradingPartners } from 'common/components/datasources/datasource'
import { TradingPartner } from 'common/types/kraken-core/TradingPartner'
import useDebouncedValue from 'hooks/useDebouncedValue'
import SimplePagination from 'atoms/SimplePagination/SimplePagination'
import ConversionCodeDetails, {
  ConversionCodeDetailsProps,
  ConversionCodeDrawerMode,
} from './ConversionCodeDetails'
import ConfirmationDialog, {
  ConfirmationDialogProps,
} from 'molecules/ConfirmationDialog/ConfirmationDialog'
import { toast } from 'react-toastify'
import qs from 'qs'
import analytics, { AnalyticsEvent, AnalyticsEventTrigger } from 'common/analytics'
import LinkToParnter from 'atoms/LinkToPartner/LinkToPartner'

const DATASOURCES = {
  TRADING_PARTNER: [
    () => ({
      type: 'string',
      adapter: {
        getKey: (tp: TradingPartner) => tp.id || '',
        getLabel: (tp: TradingPartner) => tp.name || '',
      },
      fetch: onSearchTradingPartners,
    }),
  ],
}

const generatePaginationEvent = () => {
  analytics.event({
    action: AnalyticsEventTrigger.click,
    category: AnalyticsEvent.ConversionCodesListPaginate,
  })
}

export interface ConversionCodesListProps {}

export default function ConversionCodesList(props: ConversionCodesListProps) {
  const [filters, setFilters] = useState<any>(
    qs.parse(window.location.href, {
      ignoreQueryPrefix: true,
    })
  )
  const [pagination, setPagination] = useState({
    page: 1,
    page_size: 10,
  })
  const [confirmationDialog, setConfirmationDialog] = useState<
    ConfirmationDialogProps | undefined
  >()
  const debouncedFilters = useDebouncedValue(filters, 500)
  const [selected, setSelected] = useState<Array<ConversionCode>>([])
  const [isRunningAction, setIsRunningAction] = useState(false)
  const [rows, setRows] = useState<Array<ConversionCode>>([])

  const [detailDrawer, setDetailDrawer] = useState<ConversionCodeDetailsProps>({
    id: undefined,
    mode: ConversionCodeDrawerMode.CREATE,
    open: false,
    onClose: () => {},
  })

  const { data, isLoading, isFetching, refetch } = useSearchConversionCodes({
    ...pagination,
    ...debouncedFilters,
  })

  const { mutateAsync: deleteRecord } = useDeleteConversionCode()

  const showConfirmationDialog = (title: string, content: string, callback: () => void) => {
    setConfirmationDialog({
      title,
      content,
      onConfirm: callback,
      onClose: () => {
        toggleConfirmationDialog()
      },
      open: true,
    })
  }

  const toggleConfirmationDialog = () => {
    setConfirmationDialog(c => {
      if (c) return { ...c, open: false }
      else {
        return {
          content: '',
          onConfirm: () => {},
          open: false,
          title: '',
        }
      }
    })
  }

  const isSelected = (id: string) => {
    return Boolean(selected.find(s => s.id === id))
  }

  const handleSelectRow = (row: ConversionCode) => {
    setSelected(c => [...c, row])
  }

  const handleUnselectRow = (row: ConversionCode) => {
    setSelected(current => [...current.filter(v => v.id != row.id)])
  }

  const handleSelectAll = () => {
    if (data?.results) setSelected(data.results)
  }

  const handleUnselectAll = () => {
    setSelected([])
  }

  const handleFilterChanged = (filterName: string, value: any) => {
    setFilters((c: any) => ({
      ...c,
      [filterName]: value,
    }))
  }

  const handleNextPage = () => {
    if (pagination.page) {
      generatePaginationEvent()
      setPagination(c => ({ ...c, page: (c.page || 1) + 1 }))
    }
  }

  const handlePreviousPage = () => {
    if (pagination.page && pagination.page > 1) {
      generatePaginationEvent()
      setPagination(c => ({ ...c, page: (c.page || 1) - 1 }))
    }
  }

  const handleOnPage = (pageSize: number) => {
    generatePaginationEvent()
    setPagination(p => ({ ...p, page_size: pageSize, page: 1 }))
  }

  const handleOpenDetails = (id: string) => {
    setDetailDrawer(c => ({
      ...c,
      mode: ConversionCodeDrawerMode.UPDATE,
      id,
      open: true,
    }))
  }

  const handleOnCreateNew = () => {
    setDetailDrawer(c => ({
      ...c,
      mode: ConversionCodeDrawerMode.CREATE,
      open: true,
      id: undefined,
    }))
  }

  const handleOnDeleteSelection = () => {
    analytics.event({
      category: AnalyticsEvent.ConversionCodesAction,
      action: AnalyticsEventTrigger.click,
      params: {
        action: 'delete',
      },
    })

    if (selected.length <= 0) {
      toast.warning('Select one or more records')
      return
    }

    const handleDeleteRecords = async () => {
      try {
        setIsRunningAction(true)

        const deletePromises = selected.map(async c => {
          if (c.id) {
            await deleteRecord(c.id)
            return c.id
          }
        })

        const deletedIds = await Promise.all(deletePromises.filter(Boolean))
        toggleConfirmationDialog()
        refetch()
        handleUnselectAll()
        setIsRunningAction(false)
        toast.success(`Successfully deleted ${deletedIds.length} record(s)`)
      } catch (error) {
        toast.error(`Something went wrong when deleting selected records: ${error}`)
      }
    }

    showConfirmationDialog(
      'Delete Multiple Records',
      `Are you sure you want to delete ${selected.length} records?`,
      handleDeleteRecords
    )
  }

  const COLUMNS = [
    {
      Header: '#',
      style: {
        width: '1%',
      },
      Cell(row: ConversionCode) {
        const idAsString = row.id?.slice(0, 8) || '-'

        return (
          <Tag
            className="cursor-pointer"
            variant="default"
            onClick={() => {
              if (row.id) handleOpenDetails(row.id)
            }}
          >
            {idAsString}
          </Tag>
        )
      },
    },
    {
      Header: 'Source',
      style: {
        width: '5%',
      },
      Cell(row: ConversionCode) {
        return <LinkToParnter partner={row.source as any} />
      },
    },
    {
      Header: '',
      style: {
        width: '15%',
      },
      Cell(row: ConversionCode) {
        return (
          <Layout.Stack space="s">
            <Layout.Group align="center">
              <Text variant="caption-bold">Code</Text>
              {row.source_code ? (
                <CodeBlock
                  style={{
                    width: '85%',
                  }}
                  content={row.source_code}
                />
              ) : null}
            </Layout.Group>
            <Layout.Group align="center">
              <Text variant="caption-bold">Value</Text>
              {row.source_value ? (
                <CodeBlock
                  style={{
                    width: '85%',
                  }}
                  content={row.source_value}
                />
              ) : null}
            </Layout.Group>
          </Layout.Stack>
        )
      },
    },
    {
      Header: '',
      style: {
        width: '1%',
      },
      Cell(row: ConversionCode) {
        return <Icon name="arrow-right" size={20} />
      },
    },
    {
      Header: 'Target',
      style: {
        width: '5%',
      },
      Cell(row: ConversionCode) {
        return <LinkToParnter partner={row.target as any} />
      },
    },
    {
      Header: '',
      style: {
        width: '15%',
      },
      Cell(row: ConversionCode) {
        return (
          <Layout.Stack space="s">
            <Layout.Group align="center">
              <Text variant="caption-bold">Code</Text>
              {row.target_code ? (
                <CodeBlock
                  style={{
                    width: '85%',
                  }}
                  content={row.target_code}
                />
              ) : null}
            </Layout.Group>
            <Layout.Group align="center">
              <Text variant="caption-bold">Value</Text>
              {row.target_value ? (
                <CodeBlock
                  style={{
                    width: '85%',
                  }}
                  content={row.target_value}
                />
              ) : null}
            </Layout.Group>
          </Layout.Stack>
        )
      },
    },
    {
      Header: 'Use When',
      style: {
        width: '20%',
      },
      Cell(row: ConversionCode) {
        return (
          <Layout.Stack space="none">
            {row.use_when && row.use_when instanceof Object
              ? Object.keys(row.use_when).map((key: string) => {
                  const value: any = row.use_when[key]
                  return (
                    <Layout.Group space="s" key={key}>
                      <Text variant="caption-bold">{key}</Text>
                      <Text variant="caption">{String(value)}</Text>
                    </Layout.Group>
                  )
                })
              : null}
          </Layout.Stack>
        )
      },
    },
  ]

  useEffect(() => {
    setRows(data?.results || [])
  }, [data])

  useEffect(() => {
    setPagination(c => ({
      ...c,
      page: 1,
    }))

    window.history.replaceState(null, '', `?${qs.stringify(filters, { skipNulls: true })}`)

    analytics.event({
      category: AnalyticsEvent.ConversionCodesListFilters,
      action: AnalyticsEventTrigger.change,
      params: {
        ...debouncedFilters,
      },
    })
  }, [debouncedFilters])

  return (
    <>
      {confirmationDialog ? <ConfirmationDialog {...confirmationDialog} /> : null}

      <ConversionCodeDetails
        {...detailDrawer}
        onClose={() => {
          setDetailDrawer(c => ({
            ...c,
            id: undefined,
            open: false,
          }))
          refetch()
        }}
      />

      <Layout.Stack
        style={{
          zoom: '90%',
        }}
      >
        <Card className="w-full">
          <Card.Title>
            <Layout.Switcher>
              <Card>
                <Card.Title>Source</Card.Title>
                <Card.Separator />
                <Card.Body>
                  <Layout.Switcher className="w-full">
                    <Layout.Stack space="s">
                      <Label>Partner</Label>
                      <Select
                        id="source_partner_selector"
                        name="select_source_trading_partner"
                        onChange={e => {
                          handleFilterChanged(
                            'source',
                            e.target.value ? (e.target.value as TradingPartner) : null
                          )
                        }}
                        value={filters.source}
                        datasources={DATASOURCES.TRADING_PARTNER}
                      />
                    </Layout.Stack>
                    <Layout.Stack space="s" className="w-full">
                      <Label>Code</Label>
                      <TextField
                        value={filters.source_code}
                        onChange={e => handleFilterChanged('source_code', e.target.value)}
                      />
                    </Layout.Stack>
                    <Layout.Stack space="s" className="w-full">
                      <Label>Value</Label>
                      <TextField
                        value={filters.source_value}
                        onChange={e => handleFilterChanged('source_value', e.target.value)}
                      />
                    </Layout.Stack>
                  </Layout.Switcher>
                </Card.Body>
              </Card>
              <Layout.Group style={{ maxWidth: '3rem' }} justify="center" align="center">
                <Icon name="arrow-right" size={30} />
              </Layout.Group>
              <Card>
                <Card.Title>Target</Card.Title>
                <Card.Separator />
                <Card.Body>
                  <Layout.Switcher className="w-full">
                    <Layout.Stack space="s">
                      <Label>Partner</Label>
                      <Select
                        id="target_partner_selector"
                        name="target_source_trading_partner"
                        onChange={e => {
                          handleFilterChanged(
                            'target',
                            e.target.value ? (e.target.value as TradingPartner) : null
                          )
                        }}
                        value={filters.target}
                        datasources={DATASOURCES.TRADING_PARTNER}
                      />
                    </Layout.Stack>
                    <Layout.Stack space="s" className="w-full">
                      <Label>Code</Label>
                      <TextField
                        value={filters.target_code}
                        onChange={e => handleFilterChanged('target_code', e.target.value)}
                      />
                    </Layout.Stack>
                    <Layout.Stack space="s" className="w-full">
                      <Label>Value</Label>
                      <TextField
                        value={filters.target_value}
                        onChange={e => handleFilterChanged('target_value', e.target.value)}
                      />
                    </Layout.Stack>
                  </Layout.Switcher>
                </Card.Body>
              </Card>
            </Layout.Switcher>
          </Card.Title>
          <Card.Separator />
          <Card.Body style={{ padding: 0 }}>
            <Layout.Group className="pl-4 pt-4 pb-4" align="center" justify="space-between">
              <Layout.Group align="center">
                <Dropdown>
                  <Dropdown.Trigger>
                    <Layout.Group align="center">
                      <Text>Actions</Text>
                      {selected.length > 0 ? <Tag variant="accent">{selected.length}</Tag> : null}
                    </Layout.Group>
                  </Dropdown.Trigger>
                  <Dropdown.Menu>
                    <Dropdown.Item disabled onClick={() => {}}>
                      Export
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleOnDeleteSelection}>Delete</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Layout.Stack>
                  <Layout.Group align="center">
                    {Boolean(isFetching || isLoading || isRunningAction) && <LoadingDots />}
                  </Layout.Group>
                </Layout.Stack>
              </Layout.Group>

              <Layout.Group className="pr-4 pb-4">
                <Button variant="primary" onClick={handleOnCreateNew}>
                  Create new
                </Button>
              </Layout.Group>
            </Layout.Group>

            <Table size="small" style={{ overflow: 'auto' }}>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox" style={{ width: '1%' }}>
                    <Checkbox
                      datatest-id="checkbox-select-all"
                      color="primary"
                      indeterminate={selected.length > 0 && selected.length < rows.length}
                      checked={rows.length > 0 && selected.length === rows.length}
                      onChange={e => {
                        e.target.checked ? handleSelectAll() : handleUnselectAll()
                      }}
                      inputProps={{
                        'aria-label': 'select all desserts',
                      }}
                    />
                  </TableCell>
                  {COLUMNS.map((c, index) => (
                    <TableCell style={c.style} key={`header-${index}`}>
                      <Text variant="button-sm">{c.Header}</Text>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody key={'table-body'}>
                {rows.map((row, index) => {
                  const isRowSelected = row.id ? isSelected(row.id) : false
                  return (
                    <TableRow key={`table-row-${row.id}`}>
                      <TableCell
                        key={`checkbox-${row.id}`}
                        padding="checkbox"
                        width={'2%'}
                        size="small"
                      >
                        <Checkbox
                          data-testid={`checkbox-select-${index}`}
                          color="primary"
                          checked={isRowSelected}
                          onChange={e => {
                            e.target.checked ? handleSelectRow(row) : handleUnselectRow(row)
                          }}
                        />
                      </TableCell>
                      {COLUMNS.map((column, columnIndex) => (
                        <TableCell
                          style={column.style}
                          width={column.style.width}
                          size="small"
                          key={`table-cell-${index}-${columnIndex}`}
                        >
                          {column.Cell(row)}
                        </TableCell>
                      ))}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
            {isEmpty(data?.results) && !isLoading && (
              <Layout.Stack className="p-2" space="l">
                <Text variant="body-bold">No conversion codes available.</Text>
              </Layout.Stack>
            )}
            <Layout.Box padding="s">
              <SimplePagination
                currentPage={pagination.page ?? 0}
                nextPage={handleNextPage}
                previousPage={handlePreviousPage}
                pageSize={pagination.page_size}
                canPreviousPage={pagination.page > 1}
                canNextPage={Boolean(data?.next)}
                onPageSize={handleOnPage}
              />
            </Layout.Box>
          </Card.Body>
        </Card>
      </Layout.Stack>
    </>
  )
}
