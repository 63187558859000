/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
import { TemplateOnboardDashboardView } from '../TemplateOnboard/types'
import isEmpty from 'lodash.isempty'

export const TEMPLATE_ONBOARD_DASHBOARD_VIEW_LOCAL_STORAGE_KEY =
  'template-onboard-dashboard-view-context'

export interface TemplateOnboardsDashboardContextStorageManager {
  setDatas(data: any): void
}

export interface TemplateOnboardDashboardContextLocalStorage {
  views: Array<TemplateOnboardDashboardView>
}

export class TemplateOnboardDashboardContextStoreManager {
  static init(view: TemplateOnboardDashboardView): TemplateOnboardDashboardContextLocalStorage {
    const newContext: TemplateOnboardDashboardContextLocalStorage = {
      views: [view],
    }
    this.saveDashboardContext(newContext)
    return newContext
  }

  static getDashboardContext = (): TemplateOnboardDashboardContextLocalStorage => {
    const context = JSON.parse(
      localStorage.getItem(TEMPLATE_ONBOARD_DASHBOARD_VIEW_LOCAL_STORAGE_KEY) || '{}'
    )
    return context
  }

  static saveDashboardContext = (context: TemplateOnboardDashboardContextLocalStorage): void => {
    localStorage.setItem(TEMPLATE_ONBOARD_DASHBOARD_VIEW_LOCAL_STORAGE_KEY, JSON.stringify(context))
  }

  static isNonEmptyId = (templateID: string): boolean => {
    return templateID !== undefined && templateID !== ''
  }

  static shouldSkipProcessing = (data: any): boolean => {
    return (
      (!data || isEmpty(data) || Object.keys(data).length === 0) &&
      TemplateOnboardDashboardContextStoreManager.isNonEmptyId(data.templateID)
    )
  }
  static hasItemWithTemplateID = (templateID: string): boolean => {
    const context = TemplateOnboardDashboardContextStoreManager.getDashboardContext()
    return context.views.some(view => view.templateID === templateID)
  }
}
