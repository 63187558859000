import { Chip, Grid } from '@mui/material'
import DirectionTag from 'atoms/DirectionTag/DirectionTag'
import { MessageList } from 'common/types/kraken-core/Message'
import MessageStatusTag from 'pages/Messages/common/MessageStatusTag'

export interface MessageSearchItemResultProps {
  message: MessageList
}

export default function MessageSearchItemResult({ message }: MessageSearchItemResultProps) {
  return (
    <Grid container direction={'row'} spacing={1} alignItems={'end'}>
      <Grid item sx={{ width: 90 }}>
        <Chip label={message.id?.slice(0, 8)} size="small" />
      </Grid>

      <Grid item sx={{ width: 120 }}>
        <Chip label={message.party?.name} size="small" />
      </Grid>
      <Grid item sx={{ maxWidth: 30, width: 30 }}>
        <DirectionTag direction={message.direction} />
      </Grid>
      <Grid item sx={{ width: 120 }}>
        <Chip label={message.counterparty?.name} size="small" />
      </Grid>
      <Grid item sx={{ width: 180 }}>
        <Chip label={message.transaction_type.key} size="small" />
      </Grid>

      <Grid item sx={{ maxWidth: 150, width: 150 }}>
        {message.status ? (
          <MessageStatusTag
            status={{
              status: message.status,
              description: '',
            }}
          />
        ) : null}
      </Grid>
    </Grid>
  )
}
