import { Layout } from '@loadsmart/loadsmart-ui'
import { TranslationMap } from 'common/types/kraken-core/TranslationMap'
import FormBuilder, { FormBuilderSaveCallbackResponse } from 'molecules/FormBuilder/FormBuilder'

export interface SettingsDetails {
  translationMap: TranslationMap
  onUpdate: (translationMap: TranslationMap) => void
}

export default function SettingsDetails(props: SettingsDetails) {
  const onUpdateFormBuilder = (response: FormBuilderSaveCallbackResponse) => {
    if (props.onUpdate)
      props.onUpdate({
        ...props.translationMap,
        settings_data_schema: response.schema,
        settings_ui_schema: response.uiSchema,
      })
  }

  return (
    <Layout.Stack>
      <FormBuilder
        schema={props.translationMap.settings_data_schema}
        uiSchema={props.translationMap.settings_ui_schema}
        onUpdate={onUpdateFormBuilder}
      />
    </Layout.Stack>
  )
}
