import { Avatar, Tooltip } from '@mui/material'
import Icon from 'atoms/Icon'
import styled from 'styled-components'

export interface DirectionTagProps {
  direction: 'inbound' | 'outbound'
  size?: 'small' | 'default' | 'large'
}

const StyledInboundTag = styled(Avatar)`
  background-color: #a1887f !important;
  color: #fff;
`

const StyledOutboundTag = styled(Avatar)`
  background-color: #2196f3 !important;
  color: #fff;
`

const DirectionTag = ({ direction, size = 'default' }: DirectionTagProps) => {
  const sizeMap = {
    small: 16,
    default: 24,
    large: 32,
  }

  const mappedSize = sizeMap[size]

  if (direction === 'inbound') {
    return (
      <Tooltip title={direction}>
        <StyledInboundTag sx={{ width: mappedSize, height: mappedSize }}>
          <Icon name="arrow-left" size={20} />
        </StyledInboundTag>
      </Tooltip>
    )
  }

  return (
    <Tooltip title={direction}>
      <StyledOutboundTag sx={{ width: mappedSize, height: mappedSize }}>
        <Icon name="arrow-right" size={20} />
      </StyledOutboundTag>
    </Tooltip>
  )
}

export default DirectionTag
