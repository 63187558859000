import { OktaAuth } from '@okta/okta-auth-js'
import isEmpty from 'lodash.isempty'
import { createContext, useContext, useEffect } from 'react'
import { Security } from '@okta/okta-react'
import { authManager, oktaAuth } from 'common/authManager'
import { useAppContext } from 'hooks/useUserContext/appUserContext'

export interface SecutiryState {}

export const SecurityContext = createContext<SecutiryState | undefined>(undefined)

export interface SecutiryContextProps {
  children: any
}

export function SecurityContextProvider(props: SecutiryContextProps) {
  const userContext = useAppContext()

  const restoreOriginalUri = async (_: OktaAuth, originalUri: string) => {
    if (isEmpty(originalUri)) {
      window.location.href = '/'
      return
    }
    window.location.href = originalUri
  }

  useEffect(() => {
    authManager.startService()
    authManager.subscribeOnAuthStateChange(async () => {
      const user = await authManager.getUser()
      userContext.setUser(user)
    })
  }, [])

  return (
    <SecurityContext.Provider value={{}}>
      <Security
        oktaAuth={oktaAuth}
        restoreOriginalUri={restoreOriginalUri}
        onAuthRequired={async () => {
          await authManager.signInWithRedirect()
        }}
      >
        {props.children}
      </Security>
    </SecurityContext.Provider>
  )
}

export function useSecurityContext() {
  const context = useContext(SecurityContext)
  if (context === undefined) {
    throw new Error('useSecurityContext must be used within a SecurityProvider')
  }
  return context
}
