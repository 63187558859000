import { Layout } from '@loadsmart/loadsmart-ui'
import clsx from 'clsx'
import { MessageRead } from 'common/types/kraken-core/Message'
import DirectionTag from 'atoms/DirectionTag/DirectionTag'
import TransactionTypeTag from 'atoms/TransactionTypeTag/TransactionTypeTag'
import TradingPartnerTag from 'atoms/TradingPartnerTag/TradingPartnerTag'

export interface MessageDetailHeaderProps {
  message: MessageRead
  className?: string
}

const MessageDetailHeader = ({ message, className }: MessageDetailHeaderProps) => {
  return (
    <Layout.Group align="center" space="s" className={clsx(className)}>
      <TradingPartnerTag id={message.party.id} name={message.party.name} label="Party" />
      <DirectionTag direction={message.direction} />
      <TradingPartnerTag
        id={message.counterparty.id}
        name={message.counterparty.name}
        label="Counterparty"
      />
      <TransactionTypeTag transaction_type={message.transaction_type} />
    </Layout.Group>
  )
}

export default MessageDetailHeader
