import { useCallback, useState } from 'react'
import { FallbackProps } from 'react-error-boundary'
import ContentComponentWrapper from './ContentWrapper'
import { Button, Drawer, Layout } from '@loadsmart/loadsmart-ui'
import ErrorPanel from 'molecules/ErrorPanel'
import { removeToken } from 'common/helpers/removeToken'

export default function FallbackErrorPage(props: FallbackProps) {
  const [isDetailsOpened, setDetailDrawerOpened] = useState<boolean>(false)

  const toggleDetails = useCallback(() => {
    setDetailDrawerOpened(previous => !previous)
  }, [])

  return (
    <ContentComponentWrapper>
      <Drawer open={isDetailsOpened} onClose={toggleDetails}>
        <Drawer.Body>
          <ErrorPanel error={String(props.error.stack)} />
        </Drawer.Body>
      </Drawer>

      <Layout.Stack>
        <ErrorPanel
          title="Something didn't work as expected"
          error={removeToken(String(props.error))}
        />
        <Layout.Group>
          <Button variant="primary" onClick={props.resetErrorBoundary}>
            Reload
          </Button>
          <Button onClick={toggleDetails}>Details</Button>
        </Layout.Group>
      </Layout.Stack>
    </ContentComponentWrapper>
  )
}
