import {
  MessageDirection,
  MessageStatus,
  TransactionType,
  Installation,
  IntegrationModule,
} from 'common/types/kraken-core/Message'
import {
  Label,
  Button,
  TextField,
  Select,
  ToggleGroup,
  Text,
  Tag,
  Switch,
} from '@loadsmart/loadsmart-ui'
// eslint-disable-next-line no-use-before-define
import { useEffect, useState } from 'react'
import Icon from 'atoms/Icon'
import useForceUpdate from 'hooks/useForceUpdate'
import { TradingPartner } from 'common/types/kraken-core/TradingPartner'
import EventLike from 'common/types/EventLike'
import { onSearchTransactionTypes, onSearchTradingPartners } from '../api'
import RangeDatetimePicker from 'atoms/RangeDatetimePicker/RangeDatetimePicker'
import { useMessagesDashboardViewContext } from '../MessagesDashboardViewContext'
import styled from 'styled-components'
import analytics, { AnalyticsEvent, AnalyticsEventTrigger } from 'common/analytics'
import TagSelector from 'atoms/TagsSelector/TagSelector'
import {
  onSearchInstallations,
  onSearchIntegrationModules,
  onSearchMessageStatus,
} from 'common/components/datasources/datasource'
import { capitalize } from 'lodash'
import { useMessagesDashboardContext } from '../MessageDahsboardContext'
import { Check, InputRounded, OutputRounded, Warning } from '@mui/icons-material'
import { IconButton, Grid, Stack, Tooltip } from '@mui/material'
import useDebouncedValue from 'hooks/useDebouncedValue'

const DIRECTION_FILTER_VALUES = [
  {
    label: 'Inbound',
    value: MessageDirection.INBOUND,
    icon: <InputRounded />,
  },
  {
    label: 'Outbound',
    value: MessageDirection.OUTBOUND,
    icon: <OutputRounded />,
  },
]

const DELIVERED_FILTER_VALUES = [
  {
    label: 'Delivered',
    value: 'true',
    icon: <Check />,
  },
  {
    label: 'Not Delivered',
    value: 'false',
    icon: <Warning />,
  },
]

const DATASOURCES = {
  TRADING_PARTNER: [
    () => ({
      type: 'string',
      adapter: {
        getKey: (tp: TradingPartner) => tp.id || '',
        getLabel: (tp: TradingPartner) => tp.name || '',
      },
      fetch: onSearchTradingPartners,
    }),
  ],
  TRANSACTION_TYPE: [
    () => ({
      type: 'string',
      adapter: {
        getKey: (tt: TransactionType) => tt.key || '',
        getLabel: (tt: TransactionType) => tt.description || '',
      },
      fetch: onSearchTransactionTypes,
    }),
  ],
  MESSAGE_STATUS: [
    () => ({
      type: 'string',
      adapter: {
        getKey: (entity: MessageStatus) => entity.status || '',
        getLabel: (entity: MessageStatus) => `${capitalize(entity.status)}`,
      },
      fetch: onSearchMessageStatus,
    }),
  ],
  INSTALLATION: [
    () => ({
      type: 'string',
      adapter: {
        getKey: (ins: Installation) => ins.id || '',
        getLabel: (ins: Installation) => ins.name || '',
      },
      fetch: onSearchInstallations,
    }),
  ],
  INTEGRATION_MODULE: [
    () => ({
      type: 'string',
      adapter: {
        getKey: (int: IntegrationModule) => int.id || '',
        getLabel: (int: IntegrationModule) => int.name || '',
      },
      fetch: onSearchIntegrationModules,
    }),
  ],
}

const CustomSwitch = styled(Switch)`
  zoom: 80%;
`

function MessageFilter() {
  const dashboardContext = useMessagesDashboardContext()
  const context = useMessagesDashboardViewContext()
  const [showAdvancedFilters, setShowAdvancedFilters] = useState<boolean>(false)
  const [excludeTransactionType, setExcludeTransactionType] = useState<boolean>(
    context.filters.fields?.exclude_transaction_type != null ? true : false
  )

  const forceUpdate = useForceUpdate()

  const fields = context.filters.fields
  const setField = context.filters.setField
  const updateField = context.filters.updateField

  const [referenceCode, setReferenceCode] = useState(fields.reference_code || '')
  const [pairOfPartners, setPairOfPartners] = useState<boolean>(
    fields.pair_of_partners ? JSON.parse(fields.pair_of_partners) : false
  )

  const debouncedReferenceCode = useDebouncedValue(referenceCode, 200)

  const advancedFiltersCounter = [
    fields.last_step,
    fields.exclude_last_step,
    fields.updated_at_from,
    fields.updated_at_to,
    fields.integration,
    fields.installation,
  ].filter(v => v).length

  const setComponentFiltersToDefaultValues = () => {
    setExcludeTransactionType(false)
    setPairOfPartners(false)
    setReferenceCode('')
  }

  const switchTransactionTypeFilter = () => {
    /*
      Here, we switch exclude tp's to "include tp's" because it wouldn't make
      sense to send both filters filled to the backend because they anulate each other.
    */
    if (excludeTransactionType === true && context.filters.fields.transaction_type) {
      updateField('exclude_transaction_type', context.filters.fields.transaction_type)
      updateField('transaction_type', undefined)
    } else if (context.filters.fields.exclude_transaction_type) {
      updateField('transaction_type', context.filters.fields.exclude_transaction_type)
      updateField('exclude_transaction_type', undefined)
    }
  }

  async function clearAllFilters() {
    analytics.event({
      category: AnalyticsEvent.MessageDashboardFiltersClearAll,
      action: AnalyticsEventTrigger.click,
    })

    // Update context
    await context.clearAllFilters()
    setComponentFiltersToDefaultValues()
    forceUpdate()
  }

  useEffect(() => {
    switchTransactionTypeFilter()
  }, [excludeTransactionType])

  useEffect(() => {
    switchTransactionTypeFilter()
  }, [])

  useEffect(() => {
    setField('reference_code')({ target: { value: debouncedReferenceCode } })
  }, [debouncedReferenceCode])

  useEffect(() => {
    setField('pair_of_partners')({ target: { value: pairOfPartners } })
    // When filtering by pair of partners, we need to clear the partners field
    if (pairOfPartners) {
      setField('partner')({ target: { value: [] } })
    } else {
      setField('party_id')({ target: { value: undefined } })
      setField('counterparty_id')({ target: { value: undefined } })
    }
  }, [pairOfPartners])

  return (
    <Grid container>
      <Grid item md={7} sm={1} xl={12}>
        <Grid container spacing={1}>
          <Grid item>
            <Stack spacing={1}>
              <Grid container justifyContent={'space-between'}>
                <Label>{pairOfPartners ? 'Party + Counterparty' : 'Partners'}</Label>

                <Grid item>
                  <Tooltip title="Filter by a pair of partners: party + counterparty">
                    <Grid container alignItems="center">
                      <CustomSwitch
                        data-testid="pair-of-partners-switch"
                        active={pairOfPartners}
                        onToggle={() => {
                          setPairOfPartners(c => !c)
                        }}
                      />
                    </Grid>
                  </Tooltip>
                </Grid>
              </Grid>

              {pairOfPartners ? (
                <Grid container justifyContent={'start'} alignContent={'center'}>
                  <Grid item>
                    <Select
                      id="select-party"
                      name="select-party"
                      data-testid="select-party"
                      onChange={setField('party_id')}
                      value={fields.party_id}
                      datasources={DATASOURCES.TRADING_PARTNER}
                      placeholder="Party"
                      style={{ width: '250px' }}
                    />
                  </Grid>
                  <Grid item sx={{ paddingLeft: 1 }}>
                    <Select
                      id="select-counterparty"
                      name="select-counterparty"
                      data-testid="select-counterparty"
                      onChange={setField('counterparty_id')}
                      value={fields.counterparty_id}
                      datasources={DATASOURCES.TRADING_PARTNER}
                      placeholder="Counterparty"
                      style={{ width: '250px' }}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Select
                  multiple
                  id="select-trading-partner"
                  name="select-trading-partner"
                  onChange={setField('partner')}
                  value={fields.partner}
                  datasources={DATASOURCES.TRADING_PARTNER}
                  style={{ width: '250px' }}
                />
              )}
            </Stack>
          </Grid>
          <Grid item>
            <Stack spacing={1}>
              <Label>Reference Code</Label>
              <TextField
                name="reference-code-filter-input"
                value={referenceCode}
                onChange={e => setReferenceCode(e.target.value)}
              />
            </Stack>
          </Grid>
          <Grid item>
            <Stack spacing={1}>
              <Grid container justifyContent="space-between">
                <Label>Transaction Types</Label>
                <Grid item>
                  <Tooltip title="Excludes selected transaction types">
                    <Grid container alignItems="center">
                      <CustomSwitch
                        data-testid="exclude-transaction-type-switch"
                        active={excludeTransactionType}
                        onToggle={() => setExcludeTransactionType(c => !c)}
                      />
                    </Grid>
                  </Tooltip>
                </Grid>
              </Grid>
              <Select
                multiple
                id={
                  excludeTransactionType
                    ? 'exclude-transaction-type-partner'
                    : 'transaction-type-partner'
                }
                name={
                  excludeTransactionType
                    ? 'exclude-transaction-type-partner'
                    : 'transaction-type-partner'
                }
                onChange={setField(
                  excludeTransactionType ? 'exclude_transaction_type' : 'transaction_type'
                )}
                value={
                  fields[excludeTransactionType ? 'exclude_transaction_type' : 'transaction_type']
                }
                datasources={DATASOURCES.TRANSACTION_TYPE}
                style={{ width: '250px' }}
              />
            </Stack>
          </Grid>
          <Grid item>
            <Stack spacing={1}>
              <Label>Delivered</Label>
              <ToggleGroup
                id="delivered"
                name="delivered"
                className="flex justify-center"
                onChange={(e: EventLike<any>) => {
                  const { value } = e.target
                  if (fields.delivered === value) {
                    setField('delivered')({ target: { undefined } })
                  } else {
                    setField('delivered')({ target: { value } })
                  }
                }}
                options={DELIVERED_FILTER_VALUES}
                value={fields.delivered}
              />
            </Stack>
          </Grid>

          <Grid item>
            <Stack spacing={1}>
              <Label>Direction</Label>
              <ToggleGroup
                id="direction"
                name="direction"
                className="flex justify-center"
                onChange={(e: EventLike<any>) => {
                  const { value } = e.target
                  if (fields.direction === value) {
                    setField('direction')({ target: { undefined } })
                  } else {
                    setField('direction')({ target: { value } })
                  }
                }}
                options={DIRECTION_FILTER_VALUES}
                value={fields.direction}
              />
            </Stack>
          </Grid>
        </Grid>
      </Grid>

      <Grid item paddingTop={1}>
        {showAdvancedFilters ? (
          <Grid container spacing={1} md={7} sm={1} xl={12}>
            <Grid item>
              <Stack spacing={1}>
                <Label>Integration Module</Label>
                <Select
                  name="message-integration-selector"
                  multiple
                  datasources={DATASOURCES.INTEGRATION_MODULE}
                  value={fields.integration}
                  onChange={setField('integration')}
                  style={{ width: '250px' }}
                />
              </Stack>
            </Grid>

            <Grid item>
              <Stack spacing={1}>
                <Label>Installation</Label>
                <Select
                  name="message-installation-selector"
                  multiple
                  datasources={DATASOURCES.INSTALLATION}
                  value={fields.installation}
                  onChange={setField('installation')}
                  style={{ width: '250px' }}
                />
              </Stack>
            </Grid>

            <Grid item>
              <Stack spacing={1}>
                <Label>Updated At</Label>
                <RangeDatetimePicker
                  onStartChange={value => {
                    setField('updated_at_from')({
                      target: {
                        value,
                      },
                    })
                  }}
                  onEndChange={value => {
                    setField('updated_at_to')({
                      target: {
                        value,
                      },
                    })
                  }}
                  value={{
                    start: fields.updated_at_from,
                    end: fields.updated_at_to,
                  }}
                />
              </Stack>
            </Grid>

            <Grid item>
              <Stack spacing={1}>
                <Label>Last step</Label>
                <TextField
                  name="last-step-filter-input"
                  value={fields.last_step || ''}
                  onChange={setField('last_step')}
                  data-testid="last-step-field"
                />
              </Stack>
            </Grid>

            <Grid item>
              <Stack spacing={1}>
                <Label>Exclude Last step</Label>
                <TextField
                  name="not-last-step-filter-input"
                  value={fields.exclude_last_step || ''}
                  onChange={setField('exclude_last_step')}
                  data-testid="exclude-last-step-field"
                />
              </Stack>
            </Grid>
          </Grid>
        ) : null}
      </Grid>

      <Grid item paddingTop={1}>
        <Grid container alignItems={'end'}>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <Stack spacing={1}>
                  <Label>Created At</Label>
                  <RangeDatetimePicker
                    onStartChange={value => {
                      setField('created_at_from')({
                        target: {
                          value,
                        },
                      })
                    }}
                    onEndChange={value => {
                      setField('created_at_to')({
                        target: {
                          value,
                        },
                      })
                    }}
                    value={{
                      start: fields.created_at_from,
                      end: fields.created_at_to,
                    }}
                  />
                </Stack>
              </Grid>
              <Grid item>
                <Stack spacing={1}>
                  <Label>Status</Label>
                  <Select
                    name="message-status-selector"
                    multiple
                    datasources={DATASOURCES.MESSAGE_STATUS}
                    value={fields.status}
                    onChange={setField('status')}
                    style={{
                      width: '10rem',
                    }}
                  />
                </Stack>
              </Grid>
              <Grid item>
                <Stack spacing={1}>
                  <Label>Tags</Label>
                  <TagSelector
                    values={fields.status_tags}
                    options={dashboardContext.messageDetails.messageStatusTags.map(o => ({
                      value: o.tag,
                      description: o.description,
                    }))}
                    onChange={values => {
                      setField('status_tags')({
                        target: {
                          value: values,
                        },
                      })
                    }}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems={'center'} spacing={1}>
              <Grid item>
                {advancedFiltersCounter > 0 ? (
                  <Tag variant="accent" data-testid="advanced-filter-counter">
                    {advancedFiltersCounter}
                  </Tag>
                ) : null}
              </Grid>

              <Grid item>
                <Text
                  variant="link"
                  className="cursor-pointer"
                  onClick={() => {
                    analytics.event({
                      category: AnalyticsEvent.MessageDashboardFiltersShowAdvancedFilters,
                      action: AnalyticsEventTrigger.click,
                    })
                    setShowAdvancedFilters(c => !c)
                  }}
                >
                  Advanced filters
                </Text>
              </Grid>
              <Grid item>
                <Tooltip title="Clear all filters">
                  <IconButton
                    onClick={async () => {
                      clearAllFilters()
                    }}
                  >
                    <Icon name="close" />
                  </IconButton>
                </Tooltip>
              </Grid>

              <Grid item>
                <Button
                  variant="primary"
                  id="search"
                  onClick={async () => {
                    analytics.event({
                      category: AnalyticsEvent.MessageDashboardFiltersHitSearch,
                      action: AnalyticsEventTrigger.click,
                    })
                    await context.searchMessages()
                  }}
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default MessageFilter
