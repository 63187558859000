import { Box, Typography, useTheme } from '@mui/material'
import getEnv from 'common/helpers/getEnv'
import Environment from 'common/types/Environment'

export default function EnvironmentBanner() {
  const theme = useTheme()
  const env = getEnv()
  const mapping: any = {
    [Environment.Dev]: 'Development',
    [Environment.Sandbox]: 'Sandbox',
    [Environment.QA]: 'QA',
    [Environment.Staging]: 'Staging',
  }

  return env === Environment.Production ? null : (
    <Box
      data-testid={'env-banner'}
      padding={1}
      sx={{
        backgroundColor: theme.palette.warning.main,
        width: '100%',
        textAlign: 'center',
      }}
    >
      <Typography variant="h4" color={theme.palette.warning.contrastText} fontWeight={'bold'}>
        {mapping[env]}
      </Typography>
    </Box>
  )
}
