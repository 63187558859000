import analytics from 'common/analytics'
import { User } from 'common/types/User'
import { createContext, useContext, useEffect, useState } from 'react'

export interface AppContextState {
  user: User | undefined
  userProperties: Record<string, any> | undefined
  isGlobalSearchOpen: boolean
  setUser: (user: User) => void
  setUserProperties: React.Dispatch<React.SetStateAction<Record<string, any>>>
  setGlobalSearchOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const AppContext = createContext<AppContextState | undefined>(undefined)

export interface AppContextProps {
  children: any
}

export function AppContextProvider({ children }: AppContextProps) {
  const [user, setUser] = useState<User>()
  const [isGlobalSearchOpen, setGlobalSearchOpen] = useState(false)

  const [userProperties, setUserProperties] = useState<Record<string, any>>({})

  useEffect(() => {
    const userProperties = localStorage.getItem('userProperties')
    if (userProperties) {
      setUserProperties(JSON.parse(userProperties))
    }
  }, [])

  useEffect(() => {
    localStorage.setItem('userProperties', JSON.stringify(userProperties))
  }, [userProperties])

  const handleSetUser = (user: User) => {
    setUser(user)
  }

  useEffect(() => {
    if (user) analytics.setUser(user)
  }, [user])

  return (
    <AppContext.Provider
      value={{
        user,
        userProperties,
        isGlobalSearchOpen,
        setUser: handleSetUser,
        setUserProperties,
        setGlobalSearchOpen,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export function useAppContext() {
  const context = useContext(AppContext)
  if (context === undefined) {
    throw new Error('useAppContext must be used within a AppContextProvider')
  }
  return context
}
