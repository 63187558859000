import { CSSProperties } from 'react'
import { Layout } from '@loadsmart/loadsmart-ui'
import { Themes } from '@loadsmart/loadsmart-ui/dist/theming'
import { Paper } from '@mui/material'

export interface CodeBlockProps {
  content: string
  style?: CSSProperties
}

export default function CodeBlock(props: CodeBlockProps) {
  return (
    <Paper
      variant="outlined"
      style={{ ...props.style }}
      sx={{
        padding: '0.2rem',
        backgroundColor: Themes.Loadsmart['textarea-dark-color'].toString(),
        overflow: 'auto',
      }}
    >
      <Layout.Group justify="space-between">
        <pre>
          <b>
            <code
              style={{
                color: Themes.Loadsmart['color-danger-dark'].toString(),
                overflow: 'auto',
              }}
            >
              {props.content}
            </code>
          </b>
        </pre>
      </Layout.Group>
    </Paper>
  )
}
