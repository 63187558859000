import {
  Breadcrumbs,
  Button,
  Card,
  Label,
  Layout,
  Link,
  LoadingDots,
  Switch,
  Tabs,
  Tag,
  Text,
  TextField,
} from '@loadsmart/loadsmart-ui'
import ReturnBackButton from 'atoms/ReturnBackButton/ReturnBackButton'
import { useTabTitle } from 'hooks/useTabTitle/useTabTitle'
import useTopNavigationContext from 'hooks/useTopNavigationContext/useTopNavigationContext'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useGetInstallation, useUpdateInstallation } from '../api'
import Loading from 'atoms/Loading/Loading'
import ErrorPanel from 'molecules/ErrorPanel'
import { removeToken } from 'common/helpers/removeToken'
import LSDate, { DATE_FORMAT_MMDDYYYYHHMM } from 'common/Date.helpers'
import LinkToParnter from 'atoms/LinkToPartner/LinkToPartner'
import FileViewer from 'molecules/FileViewer/FileViewer'
import { InstallationComponent, InstallationDetail } from 'common/types/kraken-core/Installation'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemText,
} from '@mui/material'
import { useTemplateOnboardDashboardContext } from '../TemplateOnboard/TemplateOnboardDashboardContext'
import InstallationViewParameterFromIntegrationTemplate from './InstallationViewParametersFromIntegrationTemplate'
import { toast } from '../../../atoms/Toast'

export interface InstallationViewProps {
  installationId?: string
}

const COMPNENT_LINK_MAPPING: Record<string, string> = {
  GatewaySettings: '/gateways',
  ConnectionSettings: '/connections',
  ConversionCode: '/conversioncodes',
}

enum INSTALLATION_SOURCES {
  UNKNOWN = 'Unknown',
  INTEGRATION_TEMPLATE = 'Integration Template Onboarding',
}

export default function InstallationView() {
  useTabTitle('Installation View')

  // TODO: We must dynamically identify the source when we have more sources
  const [installationData, setInstallationData] = useState<InstallationDetail>()
  const [installationSource, setInstallationSource] = useState(INSTALLATION_SOURCES.UNKNOWN)
  const onboardingContext = useTemplateOnboardDashboardContext()
  const [parametersRawView, setParametersRawView] = useState(false)
  const { updateState: updateTopNavigationContext } = useTopNavigationContext()
  const [groupedComponents, setGroupedComponents] = useState<
    Record<string, Array<InstallationComponent>>
  >({})
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const handleReinstall = useRef<Function>(() => {})
  const handleSave = useRef<Function>(() => {})

  const { installationId } = useParams<InstallationViewProps>()

  const { data, isLoading: isLoadingDetails, error: errorDetails } = useGetInstallation(
    installationId as any
  )

  const {
    mutate: updateInstallation,
    isLoading: isUpdating,
    isSuccess: isUpdated,
  } = useUpdateInstallation()

  handleSave.current = async () => {
    const data: any = {
      ...installationData,
    }
    updateInstallation(data as InstallationDetail)
    setIsDirty(false)
  }

  handleReinstall.current = () => {
    // TODO: Improve this logic in favor of a more dynamic way to decide which the implementation to use
    if (installationSource === INSTALLATION_SOURCES.INTEGRATION_TEMPLATE) {
      // Open new tab with the onboarding page filled with the current parameters
      const queryParams = new URLSearchParams({
        installationId: installationId || '',
        encodedInstallationParameters: btoa(
          data?.parameters ? JSON.stringify(data?.parameters) : '{}'
        ),
      })
      const newPath = `/integration/onboarding/template/${
        onboardingContext?.template?.id
      }/onboard?${queryParams.toString()}`
      window.open(newPath, '_blank')
    }
  }

  function getLinkForModel(model: string) {
    return COMPNENT_LINK_MAPPING[model]
  }

  function groupInstallationComponents() {
    if (data && data.components && data.components.length > 0) {
      const grouped: Record<string, Array<InstallationComponent>> = {}
      data.components.forEach(c => {
        if (grouped[c.model]) {
          grouped[c.model] = [...grouped[c.model], c]
        } else {
          grouped[c.model] = [c]
        }
      })
      setGroupedComponents(grouped)
    }
  }

  function identifySource() {
    let source = INSTALLATION_SOURCES.UNKNOWN

    // TODO: We currently get the onboarding template from the context, but we could change to
    // get directly from the installation entity
    if (onboardingContext && onboardingContext.template)
      source = INSTALLATION_SOURCES.INTEGRATION_TEMPLATE

    setParametersRawView(source === INSTALLATION_SOURCES.UNKNOWN)
    setInstallationSource(source)
  }

  const handleChangeAndUpdate = (key: any, value: any) => {
    setInstallationData((prev: any) =>
      prev
        ? {
            ...prev,
            [key]: value,
          }
        : null
    )
    setIsDirty(true)
  }

  useEffect(() => {
    if (isUpdated) {
      toast.success('Updated with success')
    }
  }, [isUpdated])

  useEffect(() => {
    updateTopNavigationContext({
      children: (
        <Layout.Group
          className="w-full"
          data-testid="header"
          align="center"
          justify="space-between"
        >
          <ReturnBackButton />
          <Layout.Group align="center" className="flex-1">
            <Layout.Box padding="none" className="flex-1">
              <Breadcrumbs
                entries={[
                  {
                    label: 'Installations',
                    active: false,
                  },
                  {
                    label: installationId || '',
                    active: true,
                  },
                ]}
              />
            </Layout.Box>

            <Button variant="primary" onClick={() => handleReinstall.current()}>
              Re-install
            </Button>
            {isUpdating && <LoadingDots />}
            {!isUpdating && (
              <Button variant="primary" disabled={!isDirty} onClick={() => handleSave.current()}>
                Save
              </Button>
            )}
          </Layout.Group>
        </Layout.Group>
      ),
    })
  }, [installationId, isUpdating, isDirty])

  useEffect(() => {
    if (data) {
      groupInstallationComponents()
      setInstallationData(data)
    }
    identifySource()
  }, [data?.id])

  // TODO: We can implement an dependency-like abstraction
  // In the future we could have many sources and therefore we must
  // aboid having many triggers (useEffect) for each new source
  useEffect(() => {
    identifySource()
  }, [onboardingContext?.template?.id])

  return (
    <>
      {isLoadingDetails ? (
        <Loading className="mt-8 justify-center" />
      ) : errorDetails ? (
        <ErrorPanel error={removeToken(JSON.stringify(errorDetails, null, 2))} />
      ) : (
        !!installationData && (
          <Card>
            <Card.Title>
              <Layout.Group justify="space-between" align="center">
                <Layout.Group align="center">
                  <Text variant="heading-md-bold">Installation</Text>
                  <Tag variant="accent" size="large">
                    {installationData.installation_type}
                  </Tag>
                </Layout.Group>
                <Layout.Group>
                  <Layout.Stack space="s">
                    <Text variant="caption-bold" color="color-neutral">
                      Created by
                    </Text>
                    <Text variant="caption-bold">
                      {installationData.user?.email || installationData.user?.username}
                    </Text>
                  </Layout.Stack>
                  <Layout.Stack space="s">
                    <Text variant="caption-bold" color="color-neutral">
                      Created at
                    </Text>
                    <Text variant="caption-bold">
                      {LSDate(installationData.created_at)?.format(DATE_FORMAT_MMDDYYYYHHMM)}
                    </Text>
                  </Layout.Stack>
                  <Layout.Stack space="s">
                    <Text variant="caption-bold" color="color-neutral">
                      Updated at
                    </Text>
                    <Text variant="caption-bold">
                      {LSDate(installationData.updated_at)?.format(DATE_FORMAT_MMDDYYYYHHMM)}
                    </Text>
                  </Layout.Stack>
                  <Layout.Stack space="s">
                    <Text variant="caption-bold" color="color-neutral">
                      Deleted at
                    </Text>
                    <Text variant="caption-bold">
                      {LSDate(installationData.deleted_at)?.format(DATE_FORMAT_MMDDYYYYHHMM)}
                    </Text>
                  </Layout.Stack>
                  <Layout.Stack space="s">
                    <Text variant="caption-bold" color="color-neutral">
                      Active
                    </Text>
                    <Switch
                      id="is_active"
                      name="is_active"
                      className="flex justify-center"
                      onToggle={e =>
                        handleChangeAndUpdate('is_active', !installationData.is_active)
                      }
                      active={installationData.is_active}
                    />
                  </Layout.Stack>
                </Layout.Group>
              </Layout.Group>
            </Card.Title>
            <Card.Separator />
            <Card.Body>
              <Layout.Stack space="s">
                <Layout.Group space="m" align="center" justify="space-between">
                  <Layout.Group>
                    <Layout.Stack data-testid="party" space="s" align="flex-start" justify="center">
                      <LinkToParnter partner={installationData.party} label="Party" />
                      <TextField value={installationData.party.name} readOnly />
                    </Layout.Stack>

                    <Layout.Stack data-testid="counterparty" space="s" align="flex-start">
                      <LinkToParnter partner={installationData.counterparty} label="Counterparty" />
                      <TextField value={installationData.counterparty.name} readOnly />
                    </Layout.Stack>
                  </Layout.Group>
                  <Layout.Group justify="flex-end" align="flex-end">
                    <Layout.Stack>
                      <Label>Source</Label>
                      <Tag variant="accent">{installationSource}</Tag>
                    </Layout.Stack>
                  </Layout.Group>
                </Layout.Group>

                <Layout.Group space="l" />

                <Tabs className="flex flex-col" direction="horizontal">
                  <Tabs.Items>
                    <Tabs.Item default name="tab-1">
                      Parameters
                    </Tabs.Item>
                    <Tabs.Item name="tab-2">
                      <Layout.Group align="center">
                        Components
                        <Tag variant="accent">{installationData.components?.length}</Tag>
                      </Layout.Group>
                    </Tabs.Item>
                  </Tabs.Items>

                  <Tabs.Panels className="file-view-tab">
                    <Tabs.Panel name="tab-1">
                      <Layout.Stack className="p-4">
                        <Layout.Group align="center">
                          <Switch
                            onToggle={() => setParametersRawView(c => !c)}
                            active={parametersRawView}
                          />
                          <Text variant="caption-bold">Raw visualization</Text>
                        </Layout.Group>

                        {/* TODO: in the near future, we will have to isolate InstallationView from IntegrationTemplate */}
                        {!parametersRawView &&
                        installationSource === INSTALLATION_SOURCES.INTEGRATION_TEMPLATE ? (
                          <InstallationViewParameterFromIntegrationTemplate
                            installation={installationData}
                          />
                        ) : null}

                        {parametersRawView ? (
                          <FileViewer
                            options={{
                              heigth: 500,
                              readonly: true,
                            }}
                            content={JSON.stringify(installationData.parameters, null, 2)}
                            contentType="application/json"
                          />
                        ) : null}
                      </Layout.Stack>
                    </Tabs.Panel>

                    <Tabs.Panel name="tab-2">
                      <Layout.Stack className="pt-4">
                        {Object.keys(groupedComponents).map(k => {
                          const name = k
                          const components = groupedComponents[k]
                          return (
                            <Accordion key={k}>
                              <AccordionSummary>
                                <Layout.Group>
                                  <Text>{name}</Text>
                                  <Tag variant="accent">{components.length}</Tag>
                                </Layout.Group>
                              </AccordionSummary>
                              <AccordionDetails>
                                <List>
                                  {components.map(c => {
                                    return (
                                      <ListItem key={c.id}>
                                        <ListItemText
                                          className="break-all"
                                          style={{
                                            // maxWidth: 100,
                                            alignItems: 'flex-start',
                                            alignContent: 'flex-start',
                                          }}
                                        >
                                          <Link href={`${getLinkForModel(c.model)}/${c.id}`}>
                                            <Text
                                              variant="link"
                                              style={{
                                                whiteSpace: 'break-spaces',
                                                wordBreak: 'normal',
                                                textAlign: 'left',
                                              }}
                                            >
                                              {c.metadata?.name}
                                            </Text>
                                          </Link>
                                        </ListItemText>
                                      </ListItem>
                                    )
                                  })}
                                </List>
                              </AccordionDetails>
                            </Accordion>
                          )
                        })}
                      </Layout.Stack>
                    </Tabs.Panel>
                  </Tabs.Panels>
                </Tabs>
              </Layout.Stack>
            </Card.Body>
          </Card>
        )
      )}
    </>
  )
}
