import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import {
  Breadcrumbs,
  Dialog,
  Dropdown,
  IconButton,
  Label,
  Layout,
  Table,
  Tag,
  TextField,
} from '@loadsmart/loadsmart-ui'
import Loading from 'atoms/Loading'
import SimplePagination from 'atoms/SimplePagination'
import Field from 'atoms/Field'
import LSDate, { DATE_FORMAT_MMDDYYYYHHMM } from 'common/Date.helpers'
import { RouteList } from 'common/types/kraken-core/Routes'
import useDebouncedValue from 'hooks/useDebouncedValue'

import { useDeleteRoute, useSearchRoutes } from '../api'
import Icon from 'atoms/Icon'
import { toast } from 'react-toastify'
import RouteCreateModal, { RouteCreateModalMode } from '../components/RouteCreateModal'
import useTopNavigationContext from 'hooks/useTopNavigationContext/useTopNavigationContext'
import PushableEntityStatusTag from 'common/components/development/PusableEntityStatus'
import { useTabTitle } from 'hooks/useTabTitle/useTabTitle'

const RoutesList = () => {
  useTabTitle('Routes')
  const [breadCrumbs] = useState([
    {
      label: 'Routes',
      active: true,
    },
  ])
  const [page, setPage] = useState(1)
  const [code, setCode] = useState<string>('')
  const [selectedToDelete, setSelectedToDelete] = useState<RouteList>()

  const [createModalMode, setCreateModalMode] = useState<RouteCreateModalMode>(
    RouteCreateModalMode.CREATE
  )
  const [isCreateModalOpened, setIsCreateModalOpened] = useState<boolean>(false)

  const debouncedCode = useDebouncedValue<string>(code, 500)
  const { isLoading, data, refetch } = useSearchRoutes({ page, code: debouncedCode })
  const {
    isSuccess: isDeleted,
    isLoading: isDeleting,
    error: deletedError,
    reset: deletedReset,
    mutate: deleteMutation,
  } = useDeleteRoute()

  const topNavigationContext = useTopNavigationContext()

  const handleCreate = () => {
    setCreateModalMode(RouteCreateModalMode.CREATE)
    setIsCreateModalOpened(true)
  }

  const handleImport = () => {
    setCreateModalMode(RouteCreateModalMode.IMPORT)
    setIsCreateModalOpened(true)
  }

  useEffect(() => {
    if (deletedError) {
      toast.error(`Something went wrong: ${String(deletedError)}`)
    }
    if (isDeleted) {
      toast.success('Deleted with success')
    }
    setTimeout(() => {
      deletedReset()
      refetch()
      setSelectedToDelete(undefined)
    }, 0)
  }, [isDeleted, deletedError])

  const handleCloseDeleteConfirmation = () => {
    setSelectedToDelete(undefined)
  }

  const COLUMNS = [
    {
      Header: '#',
      Cell(row: RouteList) {
        const routeIdAsString = row.id?.slice(0, 8) || '-'

        return (
          <Tag variant="default">
            <Link to={`routes/${row.id}`}>{routeIdAsString}</Link>
          </Tag>
        )
      },
    },
    {
      Header: 'Code',
      Cell(row: RouteList) {
        return row.code
      },
    },
    {
      Header: 'Status',
      alignment: 'right',
      Cell(row: RouteList) {
        return <PushableEntityStatusTag status={row.status || ''} />
      },
    },
    {
      Header: 'Updated at',
      accessor: 'updated_at',
      alignment: 'right',
      Cell(row: RouteList) {
        return LSDate(row.updated_at)?.format(DATE_FORMAT_MMDDYYYYHHMM) || '-'
      },
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      alignment: 'right',
      Cell(row: RouteList) {
        return (
          <IconButton onClick={() => setSelectedToDelete(row)}>
            <Icon name="remove" />
          </IconButton>
        )
      },
    },
  ]

  useEffect(() => {
    topNavigationContext.updateState({
      children: (
        <Layout.Group className="w-full" align="center" justify="space-between">
          <Layout.Group className="w-full" justify="space-between" align="center">
            <Breadcrumbs entries={breadCrumbs} />
            <Layout.Group justify="flex-end">
              <Dropdown>
                <Dropdown.Trigger>Actions</Dropdown.Trigger>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={handleCreate}>Create</Dropdown.Item>
                  <Dropdown.Item onClick={handleImport}>Import</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Layout.Group>
          </Layout.Group>
        </Layout.Group>
      ),
    })
  }, [breadCrumbs])

  return (
    <>
      <RouteCreateModal
        mode={createModalMode}
        opened={isCreateModalOpened}
        onClose={() => {
          setIsCreateModalOpened(false)
        }}
      />

      <Dialog
        open={!!selectedToDelete}
        onOverlayClick={handleCloseDeleteConfirmation}
        scale="small"
      >
        <Dialog.Header>Delete</Dialog.Header>
        <Dialog.Body>Are you sure you want to delete this Route?</Dialog.Body>
        <Dialog.ActionConfirm
          disabled={isDeleting}
          onConfirm={() => selectedToDelete?.id && deleteMutation(selectedToDelete.id)}
        />
        <Dialog.ActionCancel onCancel={handleCloseDeleteConfirmation} />
      </Dialog>

      {isLoading ? (
        <Loading className="mt-8" />
      ) : (
        !!data && (
          <Layout.Stack className="w-full mt-6">
            <Field className="flex">
              <Label>Route code</Label>
              <TextField
                name="code-filter-input"
                data-testid="code-filter-input"
                onChange={e => setCode(e.target.value)}
              />
            </Field>

            <Table className="w-full" data-testid="routes-list">
              <Table.Head>
                {COLUMNS.map(column => (
                  <Table.Cell key={column.Header} alignment={column.alignment as any}>
                    {column.Header}
                  </Table.Cell>
                ))}
              </Table.Head>
              <Table.Body>
                {data.results &&
                  data.results.map(row => (
                    <Table.Row key={row.id}>
                      {COLUMNS.map(cell => (
                        <Table.Cell
                          className="text-sm"
                          key={cell.Header}
                          alignment={cell.alignment as any}
                        >
                          {cell.Cell(row)}
                        </Table.Cell>
                      ))}
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>

            <Layout.Box className="flex justify-end w-full">
              <SimplePagination
                currentPage={page}
                previousPage={() => setPage(prev => prev - 1)}
                nextPage={() => setPage(prev => prev + 1)}
                canPreviousPage={!!data.previous}
                canNextPage={!!data.next}
              />
            </Layout.Box>
          </Layout.Stack>
        )
      )}
    </>
  )
}

export default RoutesList
