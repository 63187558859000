import { Tag, TagProps } from '@loadsmart/loadsmart-ui'
import { AutoMode, Check, Error, Timelapse, Warning } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import { MessageStatus } from 'common/types/kraken-core/Message'
import { isString, capitalize } from 'lodash'

export interface MessageStatusTagProps extends TagProps {
  status: string | MessageStatus
}

const ICON_SIZE = 15

const MAPPING: any = {
  created: {
    variant: 'outlined',
    icon: <AutoMode sx={{ fontSize: ICON_SIZE }} />,
    tooltip: 'Message has just been created',
  },
  processing: {
    variant: 'warning',
    icon: <Timelapse sx={{ fontSize: ICON_SIZE }} />,
    tooltip: 'Message still being processed',
  },
  failed: {
    variant: 'danger',
    icon: <Error sx={{ fontSize: ICON_SIZE }} />,
    tooltip: 'Message has failed',
  },
  processed: {
    variant: 'accent',
    icon: <Check sx={{ fontSize: ICON_SIZE }} />,
    tooltip: 'Message has been fully processed',
  },
  ignored: {
    variant: 'warning',
    icon: <Warning sx={{ fontSize: ICON_SIZE }} />,
    tooltip: 'Message has been ignored',
  },
}

export default function MessageStatusTag(props: MessageStatusTagProps) {
  const extracted = isString(props.status)
    ? {
        description: props.status,
        name: props.status,
      }
    : {
        description: props.status.description,
        name: props.status.status,
      }

  return (
    <Tooltip title={MAPPING[extracted.name].tooltip || ''}>
      <Tag variant={MAPPING[extracted.name].variant || ''} size={props.size}>
        <div style={{ whiteSpace: 'nowrap', display: 'inline-flex' }}>
          {MAPPING[extracted.name] ? MAPPING[extracted.name].icon : null}
          {capitalize(extracted.name)}
        </div>
      </Tag>
    </Tooltip>
  )
}
