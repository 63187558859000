// General imports, like internal and external libraries
import { Layout, LoadingDots, Text } from '@loadsmart/loadsmart-ui'
import Loading from 'atoms/Loading'
import { Suspense, useEffect, useState } from 'react'
import { Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router'
import { useGetRuntimeTranslationMap } from './api'
import TranslationMapDetails from './containers/TranslationMapsDetails'
import TranslationMapsList from './containers/TranslationMapsList'

import './index.css'

const TranslationMapFromRuntimeReference = () => {
  const [message, setMessage] = useState('')
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const { data, isLoading } = useGetRuntimeTranslationMap(id)

  useEffect(() => {
    if (data?.id) {
      setMessage(`Forwaring to ${data.id}`)
      history.push(`/translation-maps/${data.id}`)
    }
  }, [data])

  const Fallback = () => {
    return <>{message}</>
  }

  return (
    <Layout.Group>
      <Text>{message}</Text>
      {isLoading ? <LoadingDots /> : null}
    </Layout.Group>
  )
}

const TranslationMapsIndex = () => {
  const { path } = useRouteMatch()

  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route exact path={path}>
          <TranslationMapsList />
        </Route>
        <Route path={`${path}/:id`} exact>
          <TranslationMapDetails />
        </Route>
        <Route path={`${path}/runtime/:id`} exact>
          <TranslationMapFromRuntimeReference />
        </Route>
      </Switch>
    </Suspense>
  )
}

export default TranslationMapsIndex
