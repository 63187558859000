import { Redirect, Switch } from 'react-router-dom'
import { LoginCallback } from '@okta/okta-react'
import MessagesIndex from 'pages/Messages/MessagesIndex'
import IntegrationOnboardIndex, {
  INTEGRATION_ONBOARDING_ROUTES,
} from 'pages/IntegrationOnboarding/IntegrationOnboardingIndex'
import TradingPartnerIndex from 'pages/TradingPartner'
import RoutesIndex from 'pages/Routes'
import ConnectionsIndex from 'pages/Connections'
import GatewaySettingsIndex from 'pages/Gateway'
import TranslationMapsIndex from 'pages/TranslationMaps'
import ExpectedReplyMessageIndex from 'pages/ExpectedReplyMessage'
import { ReactNode } from 'react'
import LoginPage from 'app/LoginPage/LoginPage'
import { NotFound } from 'pages/NotFound'
import PublicRoute from './PublicRoute'
import PrivateRoute from './PrivateRoute'
import TranslationSettingsIndex from 'pages/TranslationSettings/TranslationSettingsIndex'
import PartnershipIndex from 'pages/Partnership'
import IntegrationTestDashboard from 'pages/IntegrationTest/IntegrationTestDashboard'
import ConversionCodesIndex from 'pages/ConversionCodes/ConversionCodeIndex'
import DataModelSchemasIndex from 'pages/DataModelSchema/DataModelSchemaIndex'
import PrivatePage from './PrivatePage/PrivatePage'
import { ROUTES } from './routes'

function PublicPage({ children }: { children: ReactNode }) {
  return <div>{children}</div>
}

function Router() {
  return (
    <Switch>
      <Redirect exact from="/" to={ROUTES.MESSAGES} />

      <PrivateRoute path={ROUTES.MESSAGES}>
        <PrivatePage>
          <MessagesIndex />
        </PrivatePage>
      </PrivateRoute>
      <PrivateRoute path={ROUTES.EXPECTED_REPLY_MESSAGES} strict>
        <PrivatePage>
          <ExpectedReplyMessageIndex />
        </PrivatePage>
      </PrivateRoute>
      <PrivateRoute path={INTEGRATION_ONBOARDING_ROUTES.INDEX}>
        <PrivatePage>
          <IntegrationOnboardIndex />
        </PrivatePage>
      </PrivateRoute>
      <PrivateRoute path={ROUTES.TRADING_PARTNER}>
        <PrivatePage>
          <TradingPartnerIndex />
        </PrivatePage>
      </PrivateRoute>
      <PrivateRoute path={ROUTES.ROUTES}>
        <PrivatePage>
          <RoutesIndex />
        </PrivatePage>
      </PrivateRoute>
      <PrivateRoute path={ROUTES.GATEWAYS}>
        <PrivatePage>
          <GatewaySettingsIndex />
        </PrivatePage>
      </PrivateRoute>
      <PrivateRoute path={ROUTES.CONNECTIONS}>
        <PrivatePage>
          <ConnectionsIndex />
        </PrivatePage>
      </PrivateRoute>
      <PrivateRoute path={ROUTES.TRANSLATION_MAPS}>
        <PrivatePage>
          <TranslationMapsIndex />
        </PrivatePage>
      </PrivateRoute>
      <PrivateRoute path={ROUTES.TRANSLATION_SETTINGS}>
        <PrivatePage>
          <TranslationSettingsIndex />
        </PrivatePage>
      </PrivateRoute>
      <PrivateRoute path={ROUTES.PARTNERSHIP}>
        <PrivatePage>
          <PartnershipIndex />
        </PrivatePage>
      </PrivateRoute>
      <PrivateRoute path={ROUTES.INTEGRATION_TESTS}>
        <PrivatePage>
          <IntegrationTestDashboard />
        </PrivatePage>
      </PrivateRoute>
      <PrivateRoute path={ROUTES.CONVERSION_CODES}>
        <PrivatePage>
          <ConversionCodesIndex />
        </PrivatePage>
      </PrivateRoute>
      <PrivateRoute path={ROUTES.DATA_MODEL_SCHEMAS}>
        <PrivatePage>
          <DataModelSchemasIndex />
        </PrivatePage>
      </PrivateRoute>
      <PublicRoute path={ROUTES.OIDC_LOGIN_CALLBACK}>
        <PublicPage>
          <LoginCallback />
        </PublicPage>
      </PublicRoute>

      <PublicRoute path={ROUTES.LOGIN}>
        <PublicPage>
          <LoginPage />
        </PublicPage>
      </PublicRoute>

      <PublicRoute path={ROUTES.NOT_FOUND}>
        <PublicPage>
          <NotFound />
        </PublicPage>
      </PublicRoute>
    </Switch>
  )
}

export default Router
